import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_BATTERY_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/battery_info`, filter),
            method: 'GET',
            backend_slug: 'battery_management',
            types: [
                actionTypes.GET_BATTERY_LIST_REQUEST,
                actionTypes.GET_BATTERY_LIST_SUCCESS,
                actionTypes.GET_BATTERY_LIST_FAILURE
            ]
        }
    }),

    GET_BATTERY_VENDOR_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/vendor?type=battery`,
            backend_slug: 'battery_management',
            method: 'GET',
            types: [
                actionTypes.GET_BATTERY_VENDOR_LIST_REQUEST,
                actionTypes.GET_BATTERY_VENDOR_LIST_SUCCESS,
                actionTypes.GET_BATTERY_VENDOR_LIST_FAILURE
            ]
        }
    }),

    GET_BATTERY_MODEL_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/model?type=battery`,
            backend_slug: 'battery_management',
            method: 'GET',
            types: [
                actionTypes.GET_BATTERY_MODEL_LIST_REQUEST,
                actionTypes.GET_BATTERY_MODEL_LIST_SUCCESS,
                actionTypes.GET_BATTERY_MODEL_LIST_FAILURE
            ]
        }
    }),

    GET_BMS_VENDOR_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/vendor?type=bms`,
            backend_slug: 'battery_management',
            method: 'GET',
            types: [
                actionTypes.GET_BMS_VENDOR_LIST_REQUEST,
                actionTypes.GET_BMS_VENDOR_LIST_SUCCESS,
                actionTypes.GET_BMS_VENDOR_LIST_FAILURE
            ]
        }
    }),

    GET_BMS_MODEL_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/model?type=bms`,
            backend_slug: 'battery_management',
            method: 'GET',
            types: [
                actionTypes.GET_BMS_MODEL_LIST_REQUEST,
                actionTypes.GET_BMS_MODEL_LIST_SUCCESS,
                actionTypes.GET_BMS_MODEL_LIST_FAILURE
            ]
        }
    }),

    GET_BATTERY_SUPPLIER_LIST: ()=>({
        [CALL_API]: {
            endpoint: `/vendor`,
            backend_slug: 'battery_management',
            method: 'GET',
            types: [
                actionTypes.GET_BATTERY_SUPPLIER_LIST_REQUEST,
                actionTypes.GET_BATTERY_SUPPLIER_LIST_SUCCESS,
                actionTypes.GET_BATTERY_SUPPLIER_LIST_FAILURE
            ]
        }
    }),
    GET_BATTERY_LOGS: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/historical_data/battery_logs`, payload),
            backend_slug: 'device_management',
            method: 'GET',
            types: [
                actionTypes.GET_BATTERY_LOGS_REQUEST,
                actionTypes.GET_BATTERY_LOGS_SUCCESS,
                actionTypes.GET_BATTERY_LOGS_FAILURE
            ]
        }
    }),

    GET_BATTERY_PERFORMANCE: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/historical_data/battery_performance/voltage`, payload),
            backend_slug: 'device_management',
            method: 'GET',
            types: [
                actionTypes.GET_BATTERY_PERFORMANCE_REQUEST,
                actionTypes.GET_BATTERY_PERFORMANCE_SUCCESS,
                actionTypes.GET_BATTERY_PERFORMANCE_FAILURE
            ]
        }
    }),

    GET_LIFETIME_AH_OUT: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/historical_data/battery_performance/ah_out`, payload),
            backend_slug: 'device_management',
            method: 'GET',
            types: [
                actionTypes.GET_LIFETIME_AH_OUT_REQUEST,
                actionTypes.GET_LIFETIME_AH_OUT_SUCCESS,
                actionTypes.GET_LIFETIME_AH_OUT_FAILURE
            ]
        }
    }),

    ADD_BATTERY: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/battery_info',
            method: 'POST',
            backend_slug: 'battery_management',
            body : payload,
            types: [
                actionTypes.ADD_BATTERY_REQUEST,
                actionTypes.ADD_BATTERY_SUCCESS,
                actionTypes.ADD_BATTERY_FAILURE,
            ]
        }
    }),

    UPDATE_DEVICE: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/devices/dongle',
            method: 'PUT',
            backend_slug: 'device_management',
            body : payload,
            types: [
                actionTypes.UPDATE_DEVICE_REQUEST,
                actionTypes.UPDATE_DEVICE_SUCCESS,
                actionTypes.UPDATE_DEVICE_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
