const actionNames = {
    listAction: 'search',
    createAction: 'create',
    updateAction: 'update',
    deleteAction: 'delete'
};

export const getActionName = (action) => {
    return actionNames.hasOwnProperty(action)? actionNames[action]: undefined;
};
