import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import BatteryList from "./views/batteryList/batteryList";
import CreateBattery from "./views/newBattery/CreateBattery";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";
import ChartTest from "./views/ChartTest";
import BatteryGraph from "./views/BatteryGraph";
import BatteryFleetPerformance from "./views/BatteryFleetPerformance";

// TODO: Need to update authorization related checking

export const BatteriesRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the battery if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/batteries"}
                to={"/batteries/list"}
            />

            {/* Routes to Batteries List*/}
            <SecuredRoute
                path={"/batteries/list"}
                component={BatteryList}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('listAction')}
            />

            <SecuredRoute
                path={"/batteries/create"}
                component={CreateBattery}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('createAction')}
            />

            <SecuredRoute
                path={"/batteries/edit"}
                component={CreateBattery}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('updateAction')}
            />

          <SecuredRoute
                path={"/batteries/battery_graph/:battery_serial_number"}
                component={BatteryGraph}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('listAction')}
            />
          <SecuredRoute
                path={"/batteries/battery_fleet"}
                component={BatteryFleetPerformance}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
