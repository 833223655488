import React, {useState} from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import {actions} from "../actions";
import  { Form, InputGroup, Button, Spinner } from 'react-bootstrap';
import {useForm} from "react-hook-form";
import {isValidPhoneNumber} from 'libphonenumber-js';
import BootstrapSwitchButton from "bootstrap-switch-button-react";

function Login(props) {
  const {register, handleSubmit}= useForm();
  const [invalidErrorMsg, setInvalidErrorMsg]= useState(false);
  const [english, setEnglish]= useState(true);

  const credentialSubmit=(data)=>{
    let phoneIsValidated = isValidPhoneNumber(data.username, 'BD')
    if (phoneIsValidated) {
      setInvalidErrorMsg(false)
      data["language"] = english? 'EN':'BN'
      props.LOGIN(data)
    } else {
      setInvalidErrorMsg(true)
    }
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      <div id="recaptcha-container"/>
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          {/*<FormattedMessage id="AUTH.LOGIN.TITLE" />*/}
          {english? process.env.REACT_APP_APP_NAME_ENGLISH:process.env.REACT_APP_APP_NAME_BANGLA}
        </h3>
        <br/>
        {/*<p className="text-muted font-weight-bold">*/}
        <Form
            onSubmit={handleSubmit(credentialSubmit)}
            className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          <div className="form-group fv-plugins-icon-container">
              <div className={'col-md-12 row no-padding mb-3'}>
                <div className={'col-md-12 no-padding'} style={{
                  paddingLeft: '5px'
                }}>
                  <InputGroup>
                    <InputGroup.Text><i className="fa fa-user" aria-hidden="true"/></InputGroup.Text>
                    <Form.Control
                        placeholder={english? "Phone number, ex: 01737388296":"ফোন নাম্বার, উদাহরণঃ 01737388296"}
                        type="text"
                        name="username"
                        autoComplete="off"
                        ref={register({
                          required: "required",
                        })}
                    />
                  </InputGroup>
                  {invalidErrorMsg? <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>{'Invalid phone number!'}</div>:null}
                  {props.isError && props.errorMsg && <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>{props.errorMsg}</div>}
                </div>
              </div>

              <div className={'col-md-12 row no-padding mb-3'}>
                <div className={'col-md-12 no-padding'} style={{
                  paddingLeft: '5px'
                }}>
                  <InputGroup className="mb-2">
                    <InputGroup.Text><i className="fa fa-key" aria-hidden="true"/></InputGroup.Text>
                    <Form.Control
                        placeholder={english? "Password":"পাসওয়ার্ড"}
                        type="password"
                        name="password"
                        ref={register({
                          required: "required",
                        })}
                    />
                  </InputGroup>
                  {props.isError && props.errorMsg && <div className={"text-danger float-left"}>
                    <strong>{props.errorMsg}</strong></div>}
                  {(props.authentication_request_response && !props.user)? <div className={"text-danger float-left"}>
                    <i className={"flaticon-warning kt-font-brand"}/>{props.authentication_request_response.error.message}</div>:null}
                </div>
              </div>

            <div className={'col-md-12 row no-padding d-flex justify-content-between'}>
              <div className={'d-inline'}>
                <BootstrapSwitchButton
                    checked={english}
                    size="sm"
                    onstyle="primary"
                    offstyle="warning"
                    onlabel="EN"
                    offlabel="বাংলা"
                    onChange={() => {
                      setEnglish(!english)
                    }}
                />
              </div>
              {/*<div className={'col-md-5 d-inline'}></div>*/}
              <div className={'d-inline'}>
                <Button
                    disabled={props.buttonLoading}
                    type="submit"
                    variant={english? 'primary':'warning'}
                    // className={'float-right'}
                    disabled={props.requesting_for_auth? true:false}
                >
                  {english? 'Sign In': 'সাইন ইন'}
                  {props.requesting_for_auth && <>&nbsp; &nbsp;<Spinner animation="border"  size={'sm'} variant="light"/></>}
                </Button>
              </div>
            </div>
          </div>

        </Form>
        {/*</p>*/}
      </div>
      {/* end::Head */}

    </div>
  );
}

/*Mapping the store variable to props for this component*/
const mapStateToProps = state =>{
  return {
    firebase: state.auth.firebase,
    user: state.auth.user,
    confirmation: state.auth.confirmation,
    isError: state.auth.isError,
    errorMsg: state.auth.errorMsg,
    buttonLoading: state.auth.loading,
    authentication_request_response: state.auth.authentication_request_response,
    requesting_for_auth: state.auth.requesting_for_auth
  }
};

export default injectIntl(connect(mapStateToProps, actions)(Login));
