import React, {Fragment} from "react";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "./utilityComponents/SOlTypography";

export const LoadingSpinner=({loadingSubText, size, language})=>{
    return <Fragment>
        <div className="align-content-center mt-5"  align="center">
            <h2 className={`mb-5`}>
                <span>{language === 'EN'? 'Fetching Data':'তথ্য সংগ্রহ করা হচ্ছে'}</span>
            </h2>
            <Spinner animation="border" size={size || 'sm'} variant="warning" />
            <br/>
            <SolTypography.SubTitle primary> {loadingSubText} </SolTypography.SubTitle>

        </div>
    </Fragment>
}
