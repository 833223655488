import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Breadcrumb, Button, Form, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils';
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable';
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {isValidPhoneNumber} from "libphonenumber-js";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import moment from "moment";
import {checkAuthorization} from "../../../auth/authorization";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const UserList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState(false);
    const [showFilterCriteriaMandatoryMessage, setShowFilterCriteriaMandatoryMessage] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [textFieldLabel, setTextFieldLabel] = useState(defaultTextFieldLabel);
    const [textFieldPlaceholder, setTextFieldPlaceholder] = useState(defaultTextFieldPlaceholder);
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [numberValidationMessage, setNumberValidationMessage] = useState(false);
    const [userNameLengthIsInvalid, setUserNameLengthIsInvalid] = useState(false);
    const [userNameIsInvalid, setUserNameIsInvalid] = useState(false);

    const [userList, setUserList] = useState([]);
    const [page, setPage] = useState(1);
    const [filterApplied, setFilterApplied] = useState(false);

    const [authorization, setAuthorization] = useState(null);
    const [language, setLanguage] = useState(null);
    const [userServiceName, setUserServiceName] = useState(getServiceName('userService'));
    const [userFeatureName, setUserFeatureName] = useState(getFeatureName('userFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));

    const filterCriteriaMandatoryMessage = language === 'EN'? 'Please select a filter criteria first!':'দয়া করে আগে একটি মানদণ্ড বাছাই করুন!'
    const userNameFieldLabel = language === 'EN'? 'Username':'লগইন করতে ব্যবহৃত আইডি'
    const userNameFieldPlaceHolder = language === 'EN'? 'Provide username. Ex: 01676366473':'ব্যবহারকারী লগইন করতে যে আইডি ব্যবহার করে তা লিখুন। উদাহরণঃ 01676366473'
    const generalRequiredMessage = language === 'EN'? 'Please provide above information!':'দয়া করে উপরের তথ্যটি দিন!'
    const defaultTextFieldLabel = language === 'EN'? 'Filter value':'মান'
    const defaultTextFieldPlaceholder = language === 'EN'? 'Provide a value to filter the list':'তালিকায় ব্যবহারকারী খোঁজার জন্য একটি মান লিখুন'

    const userListColumns = [
        {
            field: 'name',
            title: language === 'EN'? 'Name':'নাম',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'garage',
            title: language === 'EN'? 'Garage':'গ্যারেজ',
            emptyValue: ()=>{
                return "N/A"
            }
            // disableClick: true,
        },
        {
            field: 'username',
            title: language === 'EN'? 'User Name':'লগইন করতে ব্যবহৃত আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true,
        },
        // {
        //     field: 'role',
        //     title: language === 'EN'? 'Role':'ব্যবহারকারীর ধরন',
        //     emptyValue: ()=>{
        //         return "N/A"
        //     },
        //     // disableClick: true,
        // },
        {
            field: 'registeredAt',
            title: language === 'EN'? 'Registered at':'নিবন্ধনের সময়',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true,
        },
        {
            field: 'status',
            title: language === 'EN'? 'Status':'বর্তমান অবস্থা',
            emptyValue: ()=>{
                return "N/A"
            },
            disableClick: true,
        }
    ]

    const {
        GET_USER_LIST
    } = props;
    const classes = useStylesIconButton();

    const filterCriteriaOptions = [
        {
            'label': language === 'EN'? 'Username':'লগইন আইডি',
            'value': 'username'
        }
    ]

    const clearAllErrorMessages = () => {
        setShowFilterCriteriaMandatoryMessage(false)
        setRequiredErrorMessage(false)
        setNumberValidationMessage(false)
        setUserNameIsInvalid(false)
    }

    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the user list
            setPage(1)
            GET_USER_LIST({page: 1})
        }
        setTextFieldLabel(defaultTextFieldLabel)
        setTextFieldPlaceholder(defaultTextFieldPlaceholder)
        resetFilterValues()
        setFilterApplied(false)
        clearAllErrorMessages()
    }

    const resetFilterValues = () => {
        setFilterCriteria(null)
        setSearchText("")
    }

    const filterTheList = () => {
        if (!filterCriteria || !searchText) {
            setRequiredErrorMessage(true)
        } else {
            setRequiredErrorMessage(false)
            // Following has been done as we are only filtering based on username/ phone number
            let phoneIsValidated = isValidPhoneNumber(searchText, 'BD')
            if (filterCriteria.value === 'username' && searchText.length !== 11) {
                setUserNameLengthIsInvalid(true)
            } else if (filterCriteria.value === 'username' && !phoneIsValidated) {
                setUserNameLengthIsInvalid(false)
                setUserNameIsInvalid(true)
            } else {
                setUserNameIsInvalid(false)
                let filter = {page: 1}
                filter[filterCriteria.value] = searchText
                GET_USER_LIST(filter)
                setFilterApplied(true)
            }
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        setAuthorization(props.authorization)
        setLanguage(props.language)
        GET_USER_LIST({page: 1});
    }, [GET_USER_LIST]);

    useEffect(() => {
        setFilterCriteria(filterCriteriaOptions[0])
        setTextFieldLabel(userNameFieldLabel)
        setTextFieldPlaceholder(userNameFieldPlaceHolder)
    }, [language]);

    useEffect(() => {
        if (props.userList) {
            const userList = props.userList.results;
            if (userList && userList.length > -1) {
                console.log(userList)
                setUserList(userList.map((user, index) => {
                    let status = 'Active'
                    if (user.status === false) {
                        status = 'Inactive'
                    }
                    return {
                        ...user,
                        name: user.name,
                        garage: user.garage_name? user.garage_name:"N/A",
                        username: user.username,
                        // role: user.role_name,
                        registeredAt: moment(user.created_at).format("DD MMMM,YYYY hh:mm a"),
                        status: status
                    }
                }))
            }
        }
    }, [props.userList]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterCriteria && searchText) {
            let filter = {page: newPage}
            filter[filterCriteria.value] = searchText
            GET_USER_LIST(filter)
        } else {
            props.GET_USER_LIST({page: newPage});
        }
    }

    const gotToCreateuser = () => {
        props.history.push({
            pathname: '/users/create',
            purpose: 'create'
        })
    }

    const goToEditUser = (rowData) => {
        props.history.push({
            pathname: '/users/edit/',
            userData: rowData,
            purpose: 'update'
        })
    }

    const goToUpdatePassword = (rowData) => {
        props.history.push({
            pathname: '/users/update-password/',
            userData: rowData
        })
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Grids"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Users':'ব্যবহারকারীর তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>

                                    {checkAuthorization(authorization, userServiceName, userFeatureName, [createActionName])? <>
                                        <Button variant="warning" type="submit" style={{
                                            backgroundColor: '#F18D00 !important',
                                        }} onClick={gotToCreateuser} classes={classes} >
                                            {/*<FilterListIcon color="disabled" fontSize="large"/>*/}
                                            {language === 'EN'? <i className="fa fa-user-plus"/>:null}
                                            {language === 'EN'? 'Register User':'ব্যবহারকারী নিবন্ধন করুন'}
                                        </Button>
                                    </>:null}
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={"col-md-6"}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Filter criteria':'তালিকায় ব্যবহারকারী খোঁজার মানদণ্ড'}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <Select
                                                    name={`filterCriteria`}
                                                    placeholder={language === 'EN'? 'Select a filter criteria':'একটি মানদণ্ড বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    isDisabled={props.filterDropdownLoading}
                                                    isLoading={props.filterDropdownLoading}
                                                    maxMenuHeight={200}
                                                    value={filterCriteria}
                                                    isClearable={true}
                                                    options={filterCriteriaOptions}
                                                    isSearchable={true}
                                                    onChange={(selected) => {
                                                        setFilterCriteria(selected)
                                                        clearAllErrorMessages()
                                                        if (selected) {
                                                            setSearchText("")
                                                            setTextFieldLabel(userNameFieldLabel)
                                                            setTextFieldPlaceholder(userNameFieldPlaceHolder)
                                                        } else {
                                                            setTextFieldLabel(defaultTextFieldLabel)
                                                            setTextFieldPlaceholder(defaultTextFieldPlaceholder)
                                                            setSearchText("")
                                                        }
                                                    }}
                                                />
                                                {(requiredErrorMessage && !filterCriteria)? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={"col-md-6"}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{textFieldLabel}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'searchText'}
                                                    type="text"
                                                    placeholder={textFieldPlaceholder}
                                                    value={searchText}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        if (!filterCriteria) {
                                                            setShowFilterCriteriaMandatoryMessage(true)
                                                        } else {
                                                            if (e.target.value) {
                                                                let value = e.target.value;
                                                                setUserNameLengthIsInvalid(false)
                                                                setUserNameIsInvalid(false)
                                                                setRequiredErrorMessage(false)
                                                                setSearchText(value)
                                                                if (isNaN(value) || value.includes('.')) {
                                                                    setNumberValidationMessage(true)
                                                                } else {
                                                                    setNumberValidationMessage(false)
                                                                }
                                                            } else {
                                                                setNumberValidationMessage(false)
                                                                setSearchText("")
                                                            }
                                                        }
                                                    }}
                                                />
                                                {showFilterCriteriaMandatoryMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{filterCriteriaMandatoryMessage}</div></>:null}
                                                {requiredErrorMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}
                                                {numberValidationMessage? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Please provide only numbers!':'দয়া করে শুধু সংখ্যা লিখুন!'}</div></>:null}
                                                {userNameLengthIsInvalid? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Username must be a 11 digit phone number!':'লগইন আইডি অবশ্যই একটি ১১ সংখ্যার ফোন নাম্বার হতে হবে!'}</div></>:null}
                                                {userNameIsInvalid? <><div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{language === 'EN'? 'Invalid phone number!':'ভুল ফোন নাম্বার!'}</div></>:null}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'পুনরায় মানদণ্ড বাছাই করুন'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    disabled={(numberValidationMessage || requiredErrorMessage)? true:false}
                                                    onClick={()=> {filterTheList()}} className={'ml-3'}>
                                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                                {language === 'EN'? 'Apply filter':'খুঁজুন'}
                                            </Button>
                                        </div>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যবহারকারী নেই'}
                                columns={userListColumns}
                                data={userList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.userList?.count}
                                itemsPerPage={props.userList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                actions={checkAuthorization(authorization, userServiceName, userFeatureName, [updateActionName])? [{}]:[]}
                                actionButtonVariant={['primary', 'warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[goToEditUser, goToUpdatePassword]}
                                actionButtonText={language === 'EN'? ['Edit user', 'Update password']:['তথ্য সম্পাদনা করুন','পাসওয়ার্ড সম্পাদনা করুন']}
                            /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating user list ..': 'ব্যবহারকারীদের তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

UserList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.usersReducer.isLoading,
        userList: state.usersReducer.userList,
        listLoading: state.usersReducer.listLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(UserList));
