import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useForm, Controller} from "react-hook-form";
import { useLocation } from "react-router";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {actions as commonActions} from "../../../commonReduxActions/actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../../../utils/utilityFunctions";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {devicesReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateBattery = props => {
    const {register, errors, control, clearErrors, handleSubmit, reset, setValue} = useForm();
    const [language, setLanguage] = useState('EN');
    const [firmwares, setFirmwares] = useState([]);
    const [hardwares, setHardwares] = useState([]);

    const [batterySerial, setBatterySerial] = useState("");
    const [dongleSerial, setDongleSerial] = useState("");
    const [capacity, setCapacity] = useState("");
    const [voltage, setVoltage] = useState("");
    const [batteryVendor, setBatteryVendor] = useState("");
    const [batteryModel, setBatteryModel] = useState("");
    const [bmsVendor, setBmsVendor] = useState("");
    const [bmsModel, setBmsModel] = useState("");

    const capacities = [
        {label: '80', value: 80},
        {label: '90', value: 90}
    ]
    const voltages = [
        {label: '80', value: 80},
        {label: '90', value: 90}
    ]
    const [batteryVendors, setBatteryVendors] = useState([]);
    const [batteryModels, setBatteryModels] = useState([]);
    const [bmsVendors, setBmsVendors] = useState([]);
    const [bmsModels, setBmsModels] = useState([]);

    const [errorMessage, setErrorMessage] = useState(false);
    const [errorMessageForSerialNumberStartingDigit, setErrorMessageForSerialNumberStartingDigit] = useState(false);
    const [errorMessageForSerialNumberFirstTwoDigit, setErrorMessageForSerialNumberFirstTwoDigit] = useState(false);
    const [errorMessageForInvalidMacAddress, setErrorMessageForInvalidMacAddress] = useState(false);
    const [errorMessageForMaxMacAddressLength, setErrorMessageForMaxMacAddressLength] = useState(false);

    const generalErrorMessage = language === "EN"? "Please provide above information!":"দয়া করে উপরের তথ্যটি দিন!"

    const {
        GET_BATTERY_MODEL_LIST,
        GET_BATTERY_VENDOR_LIST,
        GET_BMS_VENDOR_LIST,
        GET_BMS_MODEL_LIST,
        UPDATE_DEVICE,
        ADD_BATTERY,
    } = props;

    useEffect(()=>{
        setLanguage(props.language)

        GET_BATTERY_MODEL_LIST()
        GET_BATTERY_VENDOR_LIST()
        GET_BMS_VENDOR_LIST()
        GET_BMS_MODEL_LIST()

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }

    },[])

    useEffect(()=>{
        if (props.batteryCreated === requestCycle.success){
            props.history.push('/batteries');
        }
    },[props.batteryCreated])

    useEffect(()=>{
        if (props.deviceUpdated === requestCycle.success){
            props.history.push('/batteries');
        }
    },[props.deviceUpdated])

    useEffect(()=>{
        const batteryModels = props.batteryModels;
        if (batteryModels && batteryModels.length >= 0) {
            setBatteryModels(batteryModels.map((batteryModel) => {
                return {
                    ...batteryModel,
                    label: batteryModel.model_name,
                    value: batteryModel.pk,
                }
            }));
        }
    },[props.batteryModels])

    useEffect(()=>{
        const batteryVendors = props.batteryVendors;
        if (batteryVendors && batteryVendors.length >= 0) {
            setBatteryVendors(batteryVendors.map((batteryVendor) => {
                return {
                    ...batteryVendor,
                    label: batteryVendor.name,
                    value: batteryVendor.pk,
                }
            }));
        }
    },[props.batteryVendors])

    useEffect(()=>{
        const bmsVendors = props.bmsVendors;
        if (bmsVendors && bmsVendors.length >= 0) {
            setBmsVendors(bmsVendors.map((bmsVendor) => {
                return {
                    ...bmsVendor,
                    label: bmsVendor.name,
                    value: bmsVendor.pk
                }
            }));
        }
    },[props.bmsVendors])

    useEffect(()=>{
        const bmsModels = props.bmsModels;
        if (bmsModels && bmsModels.length >= 0) {
            setBmsModels(bmsModels.map((bmsModel) => {
                return {
                    ...bmsModel,
                    label: bmsModel.model_name,
                    value: bmsModel.pk
                }
            }));
        }
    },[props.bmsModels])

    const submit=(data)=>{
        const payload = {
            "entity_type": "battery",
            "serial_number": data.serialNumber,
            "capacity": data.capacity,
            "voltage": data.voltage,
            "battery_vendor_guid": batteryVendor.pk,
            "battery_model_guid": batteryModel.pk,
            "bms_vendor_guid": bmsVendor.pk,
            "bms_model_guid": bmsModel.pk,
            "created_by": props.user.user_guid,
            "created_by_name": props.user.name
        }
        // ADD_BATTERY(payload)
    }

    useEffect(() => {
        if (props.successMessage !== undefined) {
            language === 'EN'? showNotifications('success', props.successMessage):toast.success("সফলভাবে ব্যাটারি নিবন্ধন সম্পন্ন হয়েছে!")
        } else if (props.errorMessage !== undefined) {
            showNotifications('error', props.errorMessage)
        }
    }, [props.successMessage, props.errorMessage])


    // TODO: Following segment will be updated while working on update battery feature
    useEffect(() => {
        if (props.successMessageForUpdate !== undefined) {
            language === 'EN'? showNotifications('success', props.successMessageForUpdate):toast.success("সফলভাবে স্মার্ট ডংগল সম্পাদনা হয়েছে!")
        } else if (props.errorMessageForUpdate !== undefined) {
            showNotifications('error', props.errorMessageForUpdate)
        }
    }, [props.successMessageForUpdate, props.errorMessageForUpdate])


    return (
       <ContentWrapper showCardHeader={false} pageTitle={'Add device'} showBackButton={false} isLoading={false}>
               <div className={"row mb-6"}>
                   <div className={"col-md-12"}>
                       <Toolbar>
                           <Toolbar.Title>
                               <h1><b>{language === 'EN'? 'Battery registration':'ব্যাটারি নিবন্ধন'}</b></h1>
                           </Toolbar.Title>
                       </Toolbar>
                   </div>
               </div>
               <div className={"row"}>
                   <div className={"col-md-12"}>
                       <Form onSubmit={handleSubmit(submit)}>
                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === "EN"? "Battery serial number":"ব্যাটারী ক্রমিক নাম্বার"}<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type={"text"}
                                           name={"batterySerial"}
                                           placeholder={language === "EN"? "Serial number of the battery. Ex: 44001100":"ব্যাটারীর ক্রমিক নাম্বার। উদাহরণঃ 44001100"}
                                           autoComplete={"off"}
                                           ref={register({
                                               required: generalErrorMessage,
                                               pattern: {
                                                   value: /^(0|[1-9][0-9]*)$/i,
                                                   message: language === "EN"? "Please Enter Numbers Only!":"ক্রমিক নাম্বার হিসেবে শুধু সংখ্যা গ্রহণযোগ্য!"
                                               }
                                               // minLength: {
                                               //     value: 8,
                                               //     message: language === "EN"? "Length of the serial number must be 8!":"ক্রমিক নাম্বারের দৈর্ঘ্য অবশ্যই ৮ হতে হবে!",
                                               // },
                                               // maxLength: {
                                               //     value: 8,
                                               //     message: language === "EN"? "Length of the serial number must be 8!":"ক্রমিক নাম্বারের দৈর্ঘ্য অবশ্যই ৮ হতে হবে!",
                                               // },
                                           })}
                                           value={batterySerial}
                                           onChange={(e) => {
                                               let value = e.target.value
                                               if (value) {
                                                   setBatterySerial(value)
                                                   // if (value.startsWith('4')) {
                                                   //     setErrorMessageForSerialNumberStartingDigit(false)
                                                   //     if (value.length === 2 && value.slice(0, 2) !== '44') {
                                                   //         setErrorMessageForSerialNumberFirstTwoDigit(true)
                                                   //     } else if (value.length === 2 && value.slice(0, 2) === '44') {
                                                   //         setErrorMessageForSerialNumberFirstTwoDigit(false)
                                                   //     }
                                                   // } else {
                                                   //     setErrorMessageForSerialNumberStartingDigit(true)
                                                   // }
                                               } else {
                                                   // setErrorMessageForSerialNumberStartingDigit(false)
                                                   // setErrorMessageForSerialNumberFirstTwoDigit(false)
                                                   setBatterySerial("")
                                               }
                                           }}
                                       />

                                       {errors.batterySerial && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.batterySerial.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === "EN"? "Dongle serial number":"ডঙ্গল ক্রমিক নাম্বার"}<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type={"text"}
                                           name={"dongleSerial"}
                                           placeholder={language === "EN"? "Serial number of the dongle. Ex: 44001100":"ডঙ্গলের ক্রমিক নাম্বার। উদাহরণঃ 44001100"}
                                           autoComplete={"off"}
                                           ref={register({
                                               required: generalErrorMessage,
                                               pattern: {
                                                   value: /^(0|[1-9][0-9]*)$/i,
                                                   message: language === "EN"? "Please Enter Numbers Only!":"ক্রমিক নাম্বার হিসেবে শুধু সংখ্যা গ্রহণযোগ্য!"
                                               }
                                               // minLength: {
                                               //     value: 8,
                                               //     message: language === "EN"? "Length of the serial number must be 8!":"ক্রমিক নাম্বারের দৈর্ঘ্য অবশ্যই ৮ হতে হবে!",
                                               // },
                                               // maxLength: {
                                               //     value: 8,
                                               //     message: language === "EN"? "Length of the serial number must be 8!":"ক্রমিক নাম্বারের দৈর্ঘ্য অবশ্যই ৮ হতে হবে!",
                                               // },
                                           })}
                                           value={dongleSerial}
                                           onChange={(e) => {
                                               let value = e.target.value
                                               if (value) {
                                                   setDongleSerial(value)
                                                   // if (value.startsWith('4')) {
                                                   //     setErrorMessageForSerialNumberStartingDigit(false)
                                                   //     if (value.length === 2 && value.slice(0, 2) !== '44') {
                                                   //         setErrorMessageForSerialNumberFirstTwoDigit(true)
                                                   //     } else if (value.length === 2 && value.slice(0, 2) === '44') {
                                                   //         setErrorMessageForSerialNumberFirstTwoDigit(false)
                                                   //     }
                                                   // } else {
                                                   //     setErrorMessageForSerialNumberStartingDigit(true)
                                                   // }
                                               } else {
                                                   // setErrorMessageForSerialNumberStartingDigit(false)
                                                   // setErrorMessageForSerialNumberFirstTwoDigit(false)
                                                   setDongleSerial("")
                                               }
                                           }}
                                       />

                                       {errors.dongleSerial && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.dongleSerial.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === "EN"? "Battery capacity (Ah)":"ব্যাটারীর সক্ষমতা (Ah)"}<span className="required text-danger">*</span></Form.Label>
                                       <Controller
                                           control={control}
                                           name={"batteryCapacity"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`batteryCapacity`}
                                                   placeholder={language === 'EN'? 'Select capacity in Ah':'সক্ষমতা বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={capacities}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       if (action === "clear") {
                                                           setCapacity("")
                                                       }
                                                       onChange(selected)
                                                       if (selected) {
                                                           setCapacity(selected)
                                                       } else {
                                                           setCapacity("")
                                                       }
                                                   }}
                                               />
                                           )}
                                       />
                                       {errors.capacity && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.capacity.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === "EN"? "Voltage (v)":"ভোল্টেজ"}<span className="required text-danger">*</span></Form.Label>
                                       <Controller
                                           control={control}
                                           name={"batteryCapacity"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`voltage`}
                                                   placeholder={language === 'EN'? 'Select voltage in V':'ভোল্টেজ বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={voltages}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       if (action === "clear") {
                                                           setVoltage("")
                                                       }
                                                       onChange(selected)
                                                       if (selected) {
                                                           setVoltage(selected)
                                                       } else {
                                                           setVoltage("")
                                                       }
                                                   }}
                                               />
                                           )}
                                       />
                                       {errors.voltage && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.voltage.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === "EN"? "Battery supplier":"ব্যাটারি সরবরাহকারী"}<span className="required text-danger">*</span></Form.Label>
                                       </div>

                                       <Controller
                                           control={control}
                                           name={"supplier"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`supplier`}
                                                   placeholder={language === 'EN'? 'Select a supplier':'সরবরাহকারী বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   isDisabled={props.batteryVendorListDropDownLoading}
                                                   isLoading={props.batteryVendorListDropDownLoading}
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={batteryVendors}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       if (action === "clear") {
                                                           setBatteryVendor("")
                                                       }
                                                       onChange(selected)
                                                       if (selected) {
                                                           setBatteryVendor(selected)
                                                       } else {
                                                           setBatteryVendor("")
                                                       }
                                                   }}
                                               />
                                           )}
                                       />

                                       {errors.batteryVendor && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.batteryVendor.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === "EN"? "BMS vendor":"বিএমএস বিক্রেতা"}<span className="required text-danger">*</span></Form.Label>
                                       </div>

                                       <Controller
                                           control={control}
                                           name={"bmsVendor"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`bmsVendor`}
                                                   placeholder={language === 'EN'? 'Select a BMS vendor':'বিএমএস বিক্রেতা বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   isDisabled={props.bmsVendorListDropDownLoading}
                                                   isLoading={props.bmsVendorListDropDownLoading}
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={bmsVendors}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       if (action === "clear") {
                                                           setBmsVendor("")
                                                       }
                                                       onChange(selected)
                                                       if (selected) {
                                                           setBmsVendor(selected)
                                                       } else {
                                                           setBmsVendor("")
                                                       }
                                                   }}
                                               />
                                           )}
                                       />

                                       {errors.bmsVendor && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.bmsVendor.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === "EN"? "BMS model":"বিএমএস মডেল"}<span className="required text-danger">*</span></Form.Label>
                                       </div>

                                       <Controller
                                           control={control}
                                           name={"bmsModel"}
                                           rules= {{ required: generalErrorMessage }}
                                           render={( { onChange, onBlur, value, name, ref },
                                                     { invalid, isTouched, isDirty }) => (
                                               <Select
                                                   name={`bmsModel`}
                                                   placeholder={language === 'EN'? 'Select a BMS model':'বিএমএস মডেল বাছাই করুন'}
                                                   classNamePrefix="react-select-sol-style"
                                                   isDisabled={props.bmsModelListDropDownLoading}
                                                   isLoading={props.bmsModelListDropDownLoading}
                                                   maxMenuHeight={200}
                                                   value={value}
                                                   isClearable={true}
                                                   control={control}
                                                   inputRef={ref}
                                                   options={bmsModels}
                                                   isSearchable={true}
                                                   onChange={(selected, {action}) => {
                                                       if (action === "clear") {
                                                           setBmsModel("")
                                                       }
                                                       onChange(selected)
                                                       if (selected) {
                                                           setBmsModel(selected)
                                                       } else {
                                                           setBmsModel("")
                                                       }
                                                   }}
                                               />
                                           )}
                                       />

                                       {errors.bmsModel && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.bmsModel.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>

                           {/* TODO: Need to add two more date fields here */}

                           <div className={'row'}>
                               <div className={"col-md-12"}>
                                   <Button variant="warning" type="submit"
                                           className={"ml-3"}
                                           disabled={(props.batteryVendorListDropDownLoading || props.bmsVendorListDropDownLoading
                                               || props.bmsModelListDropDownLoading || props.batteryInfoSubmitting)? true:false}>
                                       {props.batteryInfoSubmitting===true? <><Spinner animation={'border'} size={'sm'} variant={'light'}/> </>:null}
                                       <i className='fa fa-paper-plane' aria-hidden='true'/>&nbsp;{language === "EN"? "Register":"নিবন্ধন করুন"}
                                   </Button>
                                   <Button variant="dark" size={'md'}
                                           onClick={()=> {
                                               props.history.goBack()
                                           }} style={{backgroundColor: '#8C8C9B', outline: '#8C8C9B', border: '#8C8C9B'}}>
                                       <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === "EN"? "Cancel":"বাতিল করুন"}
                                   </Button>
                               </div>
                           </div>
                       </Form>
                   </div>
               </div>
           <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
       </ContentWrapper>
    );
};

CreateBattery.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        isLoading: state.batteryReducer.isLoading,
        language: state.auth.language,
        user: state.auth.user,
        batteryModelListDropDownLoading: state.batteryReducer.batteryModelListDropDownLoading,
        batteryModels: state.batteryReducer.batteryModels,
        batteryVendorListDropDownLoading: state.batteryReducer.batteryVendorListDropDownLoading,
        batteryVendors: state.batteryReducer.batteryVendors,
        bmsVendorListDropDownLoading: state.batteryReducer.bmsVendorListDropDownLoading,
        bmsVendors: state.batteryReducer.bmsVendors,
        bmsModelListDropDownLoading: state.batteryReducer.bmsModelListDropDownLoading,
        bmsModels: state.batteryReducer.bmsModels,
        batteryCreated: state.batteryReducer.batteryCreated,
        deviceUpdated: state.batteryReducer.deviceUpdated,
        batteryInfoSubmitting: state.batteryReducer.batteryInfoSubmitting,
        successMessage: state.batteryReducer.successMessage,
        errorMessage: state.batteryReducer.errorMessage,
        successMessageForUpdate: state.batteryReducer.successMessageForUpdate,
        errorMessageForUpdate: state.batteryReducer.errorMessageForUpdate
    }
}

export default connect(mapStateToProps, {...actions, ...commonActions}) (CreateBattery);
