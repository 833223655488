export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',

    GET_USER_LIST_REQUEST: 'GET_USER_LIST_REQUEST',
    GET_USER_LIST_SUCCESS: 'GET_USER_LIST_SUCCESS',
    GET_USER_LIST_FAILURE: 'GET_USER_LIST_FAILURE',

    GET_ORGANISATIONS_REQUEST: 'GET_ORGANISATIONS_REQUEST',
    GET_ORGANISATIONS_SUCCESS: 'GET_ORGANISATIONS_SUCCESS',
    GET_ORGANISATIONS_FAILURE: 'GET_ORGANISATIONS_FAILURE',

    GET_ROLES_REQUEST: 'GET_ROLES_REQUEST',
    GET_ROLES_SUCCESS: 'GET_ROLES_SUCCESS',
    GET_ROLES_FAILURE: 'GET_ROLES_FAILURE',

    GET_GARAGES_REQUEST: 'GET_GARAGES_REQUEST',
    GET_GARAGES_SUCCESS: 'GET_GARAGES_SUCCESS',
    GET_GARAGES_FAILURE: 'GET_GARAGES_FAILURE',

    CREATE_USER_REQUEST: 'CREATE_USER_REQUEST',
    CREATE_USER_SUCCESS: 'CREATE_USER_SUCCESS',
    CREATE_USER_FAILURE: 'CREATE_USER_FAILURE',

    EDIT_USER_REQUEST: 'EDIT_USER_REQUEST',
    EDIT_USER_SUCCESS: 'EDIT_USER_SUCCESS',
    EDIT_USER_FAILURE: 'EDIT_USER_FAILURE',

    UPDATE_PASSWORD_REQUEST: 'UPDATE_PASSWORD_REQUEST',
    UPDATE_PASSWORD_SUCCESS: 'UPDATE_PASSWORD_SUCCESS',
    UPDATE_PASSWORD_FAILURE: 'UPDATE_PASSWORD_FAILURE'
};
