import {Redirect, Route, Switch} from "react-router-dom";
import React from "react";
import BatteryLogs from "./views/batteryLogs/batteryLogs";
import SecuredRoute, {withRoles} from "../../router/SecuredRoute";
import {getServiceName} from "../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../utils/roleRelatedValues/actionNames";


export const BatteryLogRouter =(props)=> {

    return (
        <Switch>
            {/*Redirects the battery if no Particular component */}
            <Redirect
                exact={"true"}
                from={"/battery-logs"}
                to={"/battery-logs/list"}
            />

            {/* Routes to Battery Log List*/}
            <SecuredRoute
                path={"/battery-logs/list"}
                component={BatteryLogs}
                service={getServiceName('deviceService')}
                feature={getFeatureName('deviceFeature')}
                action={getActionName('listAction')}
            />

            <Redirect to="/error/error-v1" />
        </Switch>
    )
};
