import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import { Toolbar } from "./components";
import Plot from "react-plotly.js";
import { batteryLogsColumns, data, DataTableContainer, layout } from "../utils";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {DayPickerRangeController} from "react-dates";
import moment from "moment";

import {Badge, Breadcrumb, Button, Form, Spinner, OverlayTrigger, Popover} from "react-bootstrap";
import Select from "react-select";
import { SolInput } from "../../../components/SolStyledComponents/components";
import DataTable from "../../../components/dataTable/DataTable";
import { LoadingSpinner } from "../../../components/LoadingSpinnerForDataTable";
import batteryLogs from "../../batteryLog/views/batteryLogs/batteryLogs";
import { connect } from "react-redux";
import { actions } from "../actions";
import { withRoles } from "../../../router/SecuredRoute";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
import { Controller, useForm } from "react-hook-form";
const useStylesIconButton = makeStyles({
  root: {
    marginTop: '0',
    paddingTop: '4px',
  },
});
const BatteryFleetPerformance = props => {

  const classes = useStylesIconButton();
  const {register, errors, control, clearErrors, handleSubmit, reset, setValue} = useForm();
  const [showFilter, setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [start, setStartDate] = useState(null);
  const [end, setEndDate] = useState(null);
  const [language, setLanguage] = useState("EN");
  const [authorization, setAuthorization] = useState(null);
  const [voltageData, setVoltageData] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [lifetimeDischargeData, setLifetimeDischargeData] = useState(null);
  const location = useLocation();
  const {
    GET_BATTERY_PERFORMANCE,
    GET_LIFETIME_AH_OUT,
    RETURN_TO_INITIAL_STATE
  } = props

  const codeMap = {
    0: {
      code: "max_value",
      formatting_data: {
        "name": "Fleet Maximum",
        "line": {
          "color": "rgb(35,1,244)",
          "width": 3
        },
        "type": "scatter"
      }
    },
    1:  {
      code: "min_value",
      formatting_data: {
        "name": "Fleet Minimum",
        "line": {
          "color": "rgb(0,159,16)",
          "width": 3
        },
        "type": "scatter"
      }
    },
    2:  {
      code: "avg_value",
      formatting_data: {
        "name": "Fleet Average",
        "line": {
          "color": "rgb(244,1,1)",
          "width": 3
        },
        "type": "scatter"
      }
    },
  }
  const [batteryLogs, setBatteryLogs] = useState([]);

  const [focusedInput, setFocusedInput] = useState('startDate');

  const filterToggle = () => {
    setShowFilter(!showFilter)
  }

  const processFleetGraphData =(data)=>{
    if (Array.isArray(data) && data.length>0)
    {
      return data.reduce((previousValue, currentValue, currentIndex, array) => {
        previousValue.forEach((item, index)=>{
          previousValue[index]["x"].push(currentValue["generated_at"])
          previousValue[index]["y"].push(currentValue[codeMap[index]["code"]])
          previousValue[index]= {
            ...previousValue[index],
            ...codeMap[index]["formatting_data"]
          }
        })
        return previousValue
      }, [{x: [], y: []},{x: [], y: []}, {x: [], y: []}])
    }{
      return null
    }

  }

  const processLifeDishargeData =(data)=>{
    if (typeof data === 'object'&& Object.keys(data).length>0)
    {
      const data_array = Object.keys(data)

      return data_array.reduce((previous, item, index)=>{
        previous.push({
          x: data[item].map((i,currentIndex)=> currentIndex+1),
          y: data[item].map((i)=> i),
          "name": `${item}`,
          // "line": {
          //   "color": "rgb(35,1,244)",
          //   "width": 3
          // },
          // "type": "scatter",
          "mode": "markers"
        })
        return previous

      },[])
    }{
      return null
    }

  }


  useEffect(()=>{

    if (Array.isArray(props.performanceData) && props.performanceData.length>=0){
      // setBatteryLogs(props.performanceData)
      setVoltageData(processFleetGraphData(props.performanceData))
      // setLifetimeDischargeData(processLifeDishargeData(props.performanceData))
    }


  },[props.performanceData])

  useEffect(()=>{
    if (typeof props.dischargeData === 'object'&& Object.keys(props.dischargeData).length>0){
      // setBatteryLogs(props.dischargeData)
      setLifetimeDischargeData(processLifeDishargeData(props.dischargeData))

    }

  },[props.dischargeData])

  //
  // console.log({
  //   voltageData: voltageData,
  //   lifetimeDischargeData: lifetimeDischargeData,
  // })
  // console.log(props.dischargeData)
  // console.log(props.performanceData)
  const batteryLogsColumns = [
    {
      field: 'battery_serial_number',
      title: language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল',
      emptyValue: ()=>{
        return "N/A"
      },
      cellStyle: {
        color: '#F18D00',
        paddingLeft: '0'
      },
      headerStyle: {
        paddingLeft: '0'
      },
      // disableClick: true,
    },
    {
      field: 'generated_at',
      title: language === 'EN'? 'Dongle Serial':'ডঙ্গল সিরিয়াল',
      emptyValue: ()=>{
        return "N/A"
      }
      // disableClick: true,
    },
    {
      field: '0002',
      title: language === 'EN'? 'Battery voltage(V)':'ব্যাটারী ক্যাপাসিটি (Ah)',
      emptyValue: ()=>{
        return "N/A"
      },
      // disableClick: true
    },
    {
      field: '0003',
      title: language === 'EN'? 'Battery current (A)':'ব্যাটারী ভোল্টেজ (v)',
      emptyValue: ()=>{
        return "N/A"
      },
      // disableClick: true
    },
    {
      field: '0052',
      title: language === 'EN'? 'Lifetime discharge (Ah_out)':'ব্যাটারী সরবরাহকারী',
      emptyValue: ()=>{
        return "N/A"
      },


      // disableClick: true
    },
    {
      field: '0052',
      title: language === 'EN'? 'Approx. km run':'ব্যাটারী সরবরাহকারী',
      emptyValue: ()=>{
        return "N/A"
      },
    }

  ]

  const disableFutureDt = (current) => {
    const today = moment().add('1', 'days')
    return current.isAfter(today)
  }

  const filterList=(data)=>{
    setVoltageData(null)
    setLifetimeDischargeData(null)
    GET_BATTERY_PERFORMANCE({
      battery_capacity: data?.battery_capacity?.value,
      battery_voltage: data?.battery_voltage?.value,
      from_date: start?.format('YYYY-MM-DD 00:00:00'),
      to_date: end?.format('YYYY-MM-DD 23:59:59'),
    })
    GET_LIFETIME_AH_OUT({
      battery_capacity: data?.battery_capacity?.value,
      battery_voltage: data?.battery_voltage?.value,
      from_date: start?.format('YYYY-MM-DD 00:00:00'),
      to_date: end?.format('YYYY-MM-DD 23:59:59'),
    })
  }

  const onDatesChange = ({startDate, endDate}) => {
    // setRequiredErrorMessage(false)
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const resetFilter=()=>{
    RETURN_TO_INITIAL_STATE()
    GET_LIFETIME_AH_OUT()
    GET_BATTERY_PERFORMANCE()
    setStartDate(null)
    setEndDate(null)
    setDateRange('')
    clearErrors()
    reset({
      battery_capacity: null,
      battery_voltage: null,
    })
  }


  useEffect(()=>{
    setLanguage("EN")
    setAuthorization(props.authorization)
    GET_LIFETIME_AH_OUT()
    GET_BATTERY_PERFORMANCE()

    return ()=>{
      RETURN_TO_INITIAL_STATE()
    }


  },[])


  useEffect(() => {
    setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
  },[start, end])

  const handleFocusChange = (input) => {
    if (!input) {
      setFocusedInput('startDate')
    } else {
      setFocusedInput(input)
    }
  }

  const popover = (
    <Popover id="popover-basic" bsPrefix={'custom-popover'}>
      <Popover.Content bsPrefix={'custom-popover'}>
        <DayPickerRangeController
          {...props}
          startDate={start}
          endDate={end}
          numberOfMonths={2}
          minimumNights={0}
          isOutsideRange={disableFutureDt}
          onDatesChange={onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
          keepOpenOnDateSelect={true}
        />
      </Popover.Content>
    </Popover>
  )
  return (
    <div>
      <ContentWrapper isLoading={false} showBackButton={false} showCardHeader={false}>
        <h1><b>Battery fleet performance</b></h1>
        {/*<div className={'mt-5 row'}>*/}
        {/*  <div className={'col-md-12'}>*/}
        {/*    <span><img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/></span>*/}
        {/*    <span className={'ml-1 mr-5'} style={{minWidth: "100px", display: "inline-block" , padding: "0 .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Battery Details:</b></span>*/}
        {/*    <span className={'ml-5 mr-5'} style={{minWidth: "150px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Capacity: </b> {location.batteryData?.nameplate_capacity || "N/A"}</span>*/}
        {/*    <span className={'ml-5 mr-5'} style={{minWidth: "350px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Guaranteed discharge delivery:  </b>{location.batteryData?.discharge_delivery || "N/A"}</span>*/}
        {/*    <span className={'ml-5 mr-5'} style={{minWidth: "250px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Warranty end date: Aug 30, 2022</b></span>*/}
        {/*  </div>*/}


        {/*  /!*<div className={'col-md-4'}>*!/*/}
        {/*  /!*  <img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*  /!*<div className={'col-md-4'}>*!/*/}
        {/*  /!*  <img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/>*!/*/}
        {/*  /!*</div>*!/*/}
        {/*</div>*/}
        <br/>
        <br/>
        <br/>

        <div className={'row'}>
          <div className={'col-md-12'}>
            <Toolbar>
              <Toolbar.Title>
                <h1><b>Filters</b></h1>
              </Toolbar.Title>
              <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                  {/*<IconButton onClick={filterToggle} classes={classes}>*/}
                  {/*  <FilterListIcon color="disabled" fontSize="large"/>*/}
                  {/*</IconButton>*/}

                </Toolbar.ToolbarContainer.Items>
              </Toolbar.ToolbarContainer>
            </Toolbar>
          </div>
        </div>


        <>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <Form onSubmit={handleSubmit(filterList)}>
                <div className={"row g-3"}>
                  <div className={'col-md-6'}>
                    <Form.Group>
                      <div>
                        <Form.Label>{language === 'EN'? 'Date range':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                      </div>
                      <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                      overlay={popover}>
                        <SolInput
                          name={'dateRangePickerInput'}
                          readOnly={true}
                          value={dateRange}
                          placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                        />
                      </OverlayTrigger>
                      {/*{requiredErrorMessage? <><div className="text-danger">*/}
                      {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                    </Form.Group>
                  </div>
                </div>

                <div className={"row g-3"}>
                  <div className={'col-md-6'}>
                    <Form.Group>
                      <div>
                        <Form.Label>{language === 'EN'? 'Capacity':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                      </div>
                      <Controller
                        control={control}
                        name={"battery_capacity"}
                        // rules= {{ required: "R" }}
                        render={( { onChange, onBlur, value, name, ref },
                                  { invalid, isTouched, isDirty }) => (
                          <Select
                            name={`battery_capacity`}
                            placeholder={language === 'EN'? 'Select capacity in Ah':'সক্ষমতা বাছাই করুন'}
                            classNamePrefix="react-select-sol-style"
                            maxMenuHeight={200}
                            value={value}
                            isClearable={true}
                            control={control}
                            inputRef={ref}
                            options={[{ value: 80, label: 80 }, { value: 100, label: 100 }, { value: 120, label: 120 }]}
                            isSearchable={true}
                            onChange={(selected, {action}) => {
                              onChange(selected)
                            }}
                          />
                        )}
                      />
                      {errors.capacity && <div className="text-danger">
                        <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.capacity.message}</div>}
                      {/*{requiredErrorMessage? <><div className="text-danger">*/}
                      {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                    </Form.Group>
                  </div>
                </div>

                <div className={"row g-3"}>
                  <div className={'col-md-6'}>
                    <Form.Group>
                      <div>
                        <Form.Label>{language === 'EN'? 'Voltage':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                      </div>
                      <Controller
                        control={control}
                        name={"battery_voltage"}
                        // rules= {{ required: "R" }}
                        render={( { onChange, onBlur, value, name, ref },
                                  { invalid, isTouched, isDirty }) => (
                          <Select
                            name={`battery_voltage`}
                            placeholder={language === 'EN'? 'Select Voltage in Ah':'সক্ষমতা বাছাই করুন'}
                            classNamePrefix="react-select-sol-style"
                            maxMenuHeight={200}
                            value={value}
                            isClearable={true}
                            control={control}
                            inputRef={ref}
                            options={[{ value: 48, label: 48 }, { value: 60, label: 60} ]}
                            isSearchable={true}
                            onChange={(selected, {action}) => {
                              onChange(selected)
                            }}
                          />
                        )}
                      />
                      {errors.voltage && <div className="text-danger">
                        <i className="flaticon-warning kt-font-brand"/>&nbsp;&nbsp;{errors.voltage.message}</div>}
                      {/*{requiredErrorMessage? <><div className="text-danger">*/}
                      {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                    </Form.Group>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md-12 "}>
                    <Button variant="outline-dark" size={"sm"} onClick={() => {
                      resetFilter();
                    }}>
                      {language === "EN" ? "Reset filter" : "প্রাথমিক অবস্থায় ফিরে যান"}
                    </Button>
                    <Button variant="warning" size={"sm"} type="submit"
                      // disabled={serialNumberNotNumericMessage? true:false}
                      //       onClick={filterList}
                            disabled={false}
                            className={"ml-3"}
                    >
                      {props.buttonLoading === true ? <><Spinner animation={"border"} size={"md"}
                                                                 variant={"light"} /> </> : null}
                      {language === "EN" ? "Apply filter" : "তালিকায় খুঁজুন"}
                    </Button>
                  </div>
                </div>
                {/*{requiredErrorMessage ? <div className="text-danger">*/}
                {/*  <i className="flaticon-warning kt-font-brand"></i>*/}
                {/*  &nbsp;&nbsp;{generalRequiredMessage}</div> : null}*/}
              </Form>
            </div>
          </div>
          <hr />
        </>


        {lifetimeDischargeData?<>
          <br/>
          <br/>
          <br/>
          <Plot
            data={lifetimeDischargeData}
            layout={{ ...layout, "yaxis": {
                "title": {
                  "text": "Total discharge over lifetime (Ah_out)"
                },
              },
              "xaxis": {
                "title": {
                  "text": "<b>Time since warranty start (d)\n</b>",
                  "font": {
                    "color": "",
                    "family": "Arial, sans-serif",
                    "size": 14
                  }
                },

              },
            }}
          />
        </>:null}

        {voltageData? <>
          <br/>
          <br/>
          <br/>
          <Plot
            data={voltageData}
            layout={ { ...layout, "yaxis": {
                "title": {
                  "text": "Battery voltage (V)"
                },
              }, }}
          />
        </>:null}



        <br/>
        <br/>
      </ContentWrapper>

    </div>
  );
};

BatteryFleetPerformance.propTypes = {};

const mapStateToProps = (state) => {
  return {
    language: state.auth.language,
    authorization: state.auth.authorizations,
    isLoading: state.batteryReducer.isLoading,
    tableLoading: state.batteryReducer.tableLoading,
    listLoading: state.batteryReducer.listLoading,
    iconLoading: state.batteryReducer.iconLoading,
    performanceData: state.batteryReducer.performanceData,
    dischargeData: state.batteryReducer.dischargeData,
  }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryFleetPerformance));
