import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Badge, Breadcrumb, Button, Form, OverlayTrigger, Spinner} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BatteryList = (props) => {
    const [showFilter, setShowFilter] = useState(false);
    const [filterCriteria, setFilterCriteria] = useState(false);
    const [showFilterCriteriaMandatoryMessage, setShowFilterCriteriaMandatoryMessage] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [invalidBatterySerial, setInvalidBatterySerial] = useState(false);
    const [invalidDongleSerial, setInvalidDongleSerial] = useState(false);

    const [batterySerial, setBatterySerial] = useState("");
    const [dongleSerial, setDongleSerial] = useState("");
    const [batterySuppliers, setBatterySuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [capacities, setCapacities] = useState([]);
    const [selectedCapacity, setSelectedCapacity] = useState(null);
    const [voltages, setVoltages] = useState([]);
    const [selectedVoltage, setSelectedVoltage] = useState(null);

    const [batteryList, setBatteryList] = useState([]);
    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const [page, setPage] = useState(1);
    const [gridOwnersList, setGridOwnersList] = useState([]);
    const [filterParameters, setFilterParameters] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false);
    const subHeader = useSubheader();
    const [projectedParams, setProjectedParams] = useState(null);
    const { b2bOrgGuid , isSolshare } = props;

    // TODO: Need to update the following
    const [deviceServiceName, setDeviceServiceName] = useState(getServiceName('deviceService'));
    const [deviceFeatureName, setDeviceFeatureName] = useState(getFeatureName('deviceFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));
    // TODO: Need to update the above

    const generalRequiredMessage = language === 'EN'? 'Please provide at least one of the above information!':'দয়া করে অন্তত একটি তথ্য দিন!'

    const batteryListColumns = [
        {
            field: 'batterySerial',
            title: language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'dongleSerial',
            title: language === 'EN'? 'Dongle Serial':'ডঙ্গল সিরিয়াল',
            emptyValue: ()=>{
                return "N/A"
            }
            // disableClick: true,
        },
        {
            field: 'nameplateVoltage',
            title: language === 'EN'? 'Battery voltage (V)':'ব্যাটারী ভোল্টেজ (V)',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'nameplateCapacity',
            title: language === 'EN'? 'Nameplate capacity (Ah)':'নেমপ্লেট ক্যাপাসিটি (Ah)',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'referenceCapacity',
            title: language === 'EN'? 'Reference capacity by supplier':'ব্যাটারী সরবরাহকারীর দেওয়া রেফারেন্স ',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'dischargeDelivery',
            title: language === 'EN'? 'Guaranteed discharge delivery':'প্রতিশ্রুত ডিসচার্জ ডেলিভারি',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'claimReferenceCapacity',
            title: language === 'EN'? 'Claim event reference capacity level':'দাবী করা ইভেন্ট রেফারেন্স অনুসারে ক্যাপাসিটি লেভেল',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        }
    ]

    const {
        GET_BATTERY_LIST,
        GET_BATTERY_SUPPLIER_LIST
    } = props;
    const classes = useStylesIconButton();

    const filterCriteriaOptions = [
        {
            'label': language === 'EN'? 'Serial number':'ক্রমিক নাম্বার',
            'value': 'serial_number'
        }
    ]

    const clearAllErrorMessages = () => {
        setRequiredErrorMessage(false)
        setInvalidBatterySerial(false)
        setInvalidDongleSerial(false)
    }
    const resetFilter = () => {
        if (filterApplied) {
            // Resetting the battery list
            setPage(1)
            setFilterApplied(false)
            GET_BATTERY_LIST({page: 1})
        }
        resetFilterValues()
        clearAllErrorMessages()
    }

    const resetFilterValues = () => {
        setSelectedSupplier("")
        setSelectedCapacity("")
        setSelectedVoltage("")
        setBatterySerial("")
        setDongleSerial("")
    }

    const filterTheList = () => {
        if (!selectedSupplier && !selectedCapacity && !selectedVoltage && !batterySerial && !dongleSerial) {
            setRequiredErrorMessage(true)
        } else {
            setFilterApplied(true)
            let filter = {page: 1}
            if (selectedSupplier) {
                filter['battery_supplier'] = selectedSupplier.label
            }
            if (selectedCapacity) {
                filter['nameplate_capacity'] = selectedCapacity.value
            }
            if (selectedVoltage) {
                filter['nameplate_voltage'] = selectedVoltage.value
            }
            if (batterySerial) {
                filter['serial_number'] = batterySerial
            }
            if (dongleSerial) {
                filter['dongle_serial_number'] = dongleSerial
            }
            GET_BATTERY_LIST(filter)
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        GET_BATTERY_LIST({page: 1})
        GET_BATTERY_SUPPLIER_LIST()

        setCapacities([{
            label: '80',
            value: 80
        }, {
            label: '100',
            value: 100
        }, {
            label: '120',
            value: 120
        }])

        setVoltages([{
            label: '48',
            value: 48
        }, {
            label: '60',
            value: 60
        }])
        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);

    useEffect(() => {
        if (props.batterySuppliers) {
            const batterySuppliers = props.batterySuppliers
            setBatterySuppliers(batterySuppliers.map((supplier, index) => {
                return {
                    ...supplier,
                    label: supplier.name,
                    value: supplier.pk
                }
            }))
        }
    }, [props.batterySuppliers]);

    useEffect(() => {
        if (props.batteryList) {
            const batteries = props.batteryList.results;
            if (batteries && batteries.length > -1) {
                setBatteryList(batteries.map((battery, index) => {
                    return {
                        ...battery,
                        batterySerial: battery.serial_number,
                        dongleSerial: battery.dongle_serial_number,
                        capacity: battery.capacity,
                        voltage: battery.voltage,
                        nameplateCapacity: battery.nameplate_capacity,
                        nameplateVoltage: battery.nameplate_voltage,
                        capacityBySupplier: battery.capacity,
                        dischargeDelivery: battery.discharge_delivery,
                        referenceCapacity: battery.reference_capacity,
                        claimReferenceCapacity: battery.claim_reference_capacity,
                        warranty_end_date: battery.warranty_end_date,
                        warranty_start_date: battery.warranty_start_date,
                    }
                }))
            }
        }
    }, [props.batteryList]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterApplied) {
            let filter = {page: newPage}
            if (selectedSupplier) {
                filter['battery_supplier'] = selectedSupplier.label
            }
            if (selectedCapacity) {
                filter['nameplate_capacity'] = selectedCapacity.value
            }
            if (selectedVoltage) {
                filter['nameplate_voltage'] = selectedVoltage.value
            }
            if (batterySerial) {
                filter['serial_number'] = batterySerial
            }
            if (dongleSerial) {
                filter['dongle_serial_number'] = dongleSerial
            }
            GET_BATTERY_LIST(filter)
        } else {
            props.GET_BATTERY_LIST({page: newPage});
        }
    }

    // const goToAddBattery = () => {
    //     props.history.push({
    //         pathname: '/batteries/create',
    //         purpose: 'create'
    //     })
    // }

    const viewBatteryLog = (rowData) => {
        props.history.push({
            pathname: `/batteries/battery_graph/${rowData.serial_number}`,
            purpose: 'details',
            batteryData: rowData
        })
    }

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Smart Dongles"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Batteries':'ব্যাটারীর তালিকা'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                    {/*{checkAuthorization(authorization, deviceServiceName, deviceFeatureName, [createActionName])? <>*/}
                                    {/*    <Button variant="warning" size={'sm'} type="submit" style={{*/}
                                    {/*        backgroundColor: '#F18D00 !important',*/}
                                    {/*    }} onClick={goToAddBattery} classes={classes} >*/}
                                    {/*        <i className="fa fa-plus"/>{language === 'EN'? 'Add battery': 'ব্যাটারী নিবন্ধন'}*/}
                                    {/*    </Button>*/}
                                    {/*</>:null}*/}
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Supplier':'সরবরাহকারী'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`batterySupplier`}
                                                    placeholder={language === 'EN'? 'Select a supplier':'একটি সরবরাহকারী বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedSupplier}
                                                    isLoading={props.batterySuppliersDropDownLoading}
                                                    isClearable={true}
                                                    options={batterySuppliers}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        clearAllErrorMessages()
                                                        if (action === "clear") {
                                                            setSelectedSupplier("")
                                                        }
                                                        if (selected) {
                                                            setSelectedSupplier(selected)
                                                        } else {
                                                            setSelectedSupplier("")
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Capacity':'সক্ষমতা'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`capacity`}
                                                    placeholder={language === 'EN'? 'Select capacity':'সক্ষমতা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedCapacity}
                                                    isClearable={true}
                                                    options={capacities}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        clearAllErrorMessages()
                                                        if (action === "clear") {
                                                            setSelectedCapacity("")
                                                        }
                                                        if (selected) {
                                                            setSelectedCapacity(selected)
                                                        } else {
                                                            setSelectedCapacity("")
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Voltage':'ভোল্টেজ'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`voltage`}
                                                    placeholder={language === 'EN'? 'Select voltage':'ভোল্টেজ বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedVoltage}
                                                    isClearable={true}
                                                    options={voltages}
                                                    isSearchable={true}
                                                    onChange={(selected, {action}) => {
                                                        clearAllErrorMessages()
                                                        if (action === "clear") {
                                                            setSelectedVoltage("")
                                                        }
                                                        if (selected) {
                                                            setSelectedVoltage(selected)
                                                        } else {
                                                            setSelectedVoltage("")
                                                        }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Battery serial':'ব্যাটারী সিরিয়াল'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'batterySerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Battery serial. Ex: 44000001':'ব্যাটারীর ক্রমিক নাম্বার। উদাহরণঃ 44000001'}
                                                    value={batterySerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setBatterySerial(value)
                                                            // if (isNaN(value) || value.includes('.')) {
                                                            //     setInvalidBatterySerial(true)
                                                            // } else {
                                                            //     setInvalidBatterySerial(false)
                                                            // }
                                                        } else {
                                                            setBatterySerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidBatterySerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid battery serial!':'ভুল ' +
                                                    'ব্যাটারী সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Dongle serial':'ডঙ্গল সিরিয়াল'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'dongleSerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Dongle serial. Ex: 44000001':'ডঙ্গলের সিরিয়াল। উদাহরণঃ 44000001'}
                                                    value={dongleSerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setDongleSerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidDongleSerial(true)
                                                            } else {
                                                                setInvalidDongleSerial(false)
                                                            }
                                                        } else {
                                                            setDongleSerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidDongleSerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid Dongle serial!':'ভুল ' +
                                                    'ডঙ্গল সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    // disabled={serialNumberNotNumericMessage? true:false}
                                                    disabled={false}
                                                    onClick={()=> {filterTheList()}} className={'ml-3'}>
                                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                                {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                            </Button>
                                        </div>
                                    </div>
                                    {requiredErrorMessage? <div className="text-danger">
                                        <i className="flaticon-warning kt-font-brand"></i>
                                        &nbsp;&nbsp;{generalRequiredMessage}</div>:null}
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন ব্যাটারী নেই'}
                                columns={batteryListColumns}
                                data={batteryList}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.batteryList?.count}
                                itemsPerPage={props.batteryList?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                // actions={checkAuthorization(authorization, deviceServiceName, deviceFeatureName, [updateActionName])? [{}]:[]}
                                actions={[{}]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[viewBatteryLog]}
                                actionButtonText={language === 'EN'? ['View log']:['লগ দেখুন']}
                            /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating battery list ..': 'ব্যাটারীর তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

BatteryList.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryReducer.isLoading,
        tableLoading: state.batteryReducer.tableLoading,
        statusCode: state.batteryReducer.statusCode,
        batteryList: state.batteryReducer.batteryList,
        filterTriggeredLoading: state.batteryReducer.filterTriggeredLoading,
        batterySuppliersDropDownLoading: state.batteryReducer.batterySuppliersDropDownLoading,
        batterySuppliers: state.batteryReducer.batterySuppliers,
        listLoading: state.batteryReducer.listLoading,
        iconLoading: state.batteryReducer.iconLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryList));
