import {actionTypes} from "./actionTypes";
import { requestCycle} from "../../utils/utilityFunctions";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
};

/*Reducer for Device's redux store*/
export const commonReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_DEVICE_LIST_REQUEST:
            return {
                ...state,
                listLoading: true
            };

        case actionTypes.GET_DEVICE_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                deviceList: action.payload
            };

        case actionTypes.GET_DEVICE_LIST_FAILURE:
            // toast.error("Can't load the device list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_FIRMWARE_LIST_REQUEST:
            return {
                ...state,
            };

        case actionTypes.GET_FIRMWARE_LIST_SUCCESS:

            return {
                ...state,
                firmwares: action.payload
            };

        case actionTypes.GET_FIRMWARE_LIST_FAILURE:

            return {
                ...state,
            };
        case actionTypes.GET_HARDWARE_LIST_REQUEST:
            return {
                ...state,
                hardwareCreated: undefined
            };

        case actionTypes.GET_HARDWARE_LIST_SUCCESS:
            return {
                ...state,
                hardwareList: action.payload
            };

        case actionTypes.GET_HARDWARE_LIST_FAILURE:
            // toast.error("Can't load the hardware list, because: " + action.payload.error.msg);
            return {
                ...state,
                listLoading: false
            };

        default:
            return state;
    }
};
