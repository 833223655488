import React, {Fragment} from "react";
import DateTimeFormat from "../../utils/dateFormat/DateTimeFormat";
import styled from "styled-components";
import {Spinner} from "react-bootstrap";
import {SolTypography} from "../../components/utilityComponents/SOlTypography";
import {isNumber} from "../../utils/utilityFunctions";


export const DataTableContainer = styled.div`
  padding: 4rem 0 0 0;
  min-height: 25rem;
  height: auto;
`

export const requestCycle = {
    success: 'success',
    pending: 'pending',
    failed: 'failed',
}
  [
  {
      "device_serial_number": "3",
      "dongle_serial_number": "44000002",
      "battery_serial_number": "55000004",
      "0002": 21.0, // voltage
      "generated_at": "2022-08-17 10:32:53",
      "0003": 31.0, //current
      "0010": 28.0 // lifetime ah
  },
    {
        "device_serial_number": "3",
        "dongle_serial_number": "44000002",
        "battery_serial_number": "55000004",
        "0002": 20.0,
        "generated_at": "2022-08-17 10:32:53",
        "0003": 41.0,
        "0052": 43.0 // lifetime ah
    },
    {
        "device_serial_number": "2",
        "dongle_serial_number": "44000050",
        "battery_serial_number": "55000002",
        "0002": 21.0,
        "generated_at": "2022-08-17 09:56:35",
        "0003": 31.0,
        "0052": 28.0
    },
    {
        "device_serial_number": "2",
        "dongle_serial_number": "44000050",
        "battery_serial_number": "55000002",
        "0002": 20.0,
        "generated_at": "2022-08-17 09:56:35",
        "0003": 41.0,
        "0052": 43.0
    }
  ]

export const data = [
    {
        "x": [
            "2015-02-17T17:43:12.516019",
            "2015-03-17T17:43:12.516019",
            "2015-04-17T17:43:12.516019",
            "2015-05-17T07:43:12.516019",
            "2015-06-17T07:43:12.516019",
            "2015-07-17T17:43:12.516019",
            "2015-08-17T17:43:12.516019",
            "2015-09-17T17:43:12.516019",
            "2015-10-17T17:43:12.516019",
            "2015-11-17T17:43:12.516019",
            "2015-12-17T17:43:12.516019",
            "2016-01-17T17:43:12.516019",
            "2016-02-17T17:43:12.516019",
            "2016-02-17T17:43:12.516019",
            "2016-02-17T07:43:12.516019",
            "2016-02-17T07:43:12.516019",
            "2016-02-17T17:43:12.516019",
            "2016-02-17T17:43:12.516019",
            "2017-02-17T17:43:12.516019",
            "2018-02-17T17:43:12.516019",
            "2019-02-17T17:43:12.516019",
            "2020-02-17T17:43:12.516019",
            "2020-03-17T07:43:12.516019",
            "2020-04-18T07:43:12.516019",
            "2021-05-17T17:43:12.516019",
        ],
        "y": [
            "0.47884927066450567",
            "0.48540272003382423",
            "0.4886952998379255",
            "0.49769836516101756",
            "0.5091417976182088",
            "0.5116244450708196",
            "0.5157269572264112",
            "0.5361417447678106",
            "0.5239465153970827",
            "0.5362078077654853",
            "0.5264754069480657",
            "0.5240773201324784",
            "0.5338916390670144",
            "0.5363954266788811",
            "0.5372516031287438",
            "0.547886424494398",
            "0.5544372313438096",
            "0.553653724191389",
            "0.5585780600380524",
            "0.5716532485378059",
            "0.5545667148192518",
            "0.5537845289267848",
            "0.5611531956874076",
            "0.5897531886406879",
            "0.5906305052498064"
        ],
        "name": "Market income",
        "line": {
            "color": "rgb(0, 67, 104)",
            "width": 3
        },
        "type": "scatter"
    },
    // {
    //     "x": [
    //         "1979.0680713128038",
    //         "1980",
    //         "1980.97244732577",
    //         "1982.998379254457",
    //         "1984.9837925445704",
    //         "1985.9967585089141",
    //         "1987.0097244732576",
    //         "1988.0226904376013",
    //         "1989.6029173419772",
    //         "1991.0210696920583",
    //         "1992.034035656402",
    //         "1993.0875202593193",
    //         "1993.938411669368",
    //         "1994.9918962722852",
    //         "1997.0178282009724",
    //         "1998.1118314424634",
    //         "1999.0032414910859",
    //         "2000.0162074554296",
    //         "2001.029173419773",
    //         "2002.0421393841166",
    //         "2003.0145867098865",
    //         "2004.02755267423",
    //         "2005.0405186385738",
    //         "2006.9854132901135"
    //     ],
    //     "y": [
    //         "0.3663505919244592",
    //         "0.36964185046860687",
    //         "0.37782573462053415",
    //         "0.4064244063138609",
    //         "0.4211630610950603",
    //         "0.443206962159115",
    //         "0.41959868931012617",
    //         "0.43512085124374605",
    //         "0.42783542386019313",
    //         "0.4189142766542175",
    //         "0.42628426467479397",
    //         "0.4206120956944543",
    //         "0.41900940737086895",
    //         "0.419858977520964",
    //         "0.44519677964907345",
    //         "0.44686288845042643",
    //         "0.453413695299838",
    //         "0.46893585723345793",
    //         "0.44614280177577353",
    //         "0.44209974631808907",
    //         "0.4478379782961033",
    //         "0.4658057924036363",
    //         "0.47969751955464746",
    //         "0.4895435487280673"
    //     ],
    //     "name": "Market income plus transfers minus federal taxes (actual)",
    //     "line": {
    //         "color": "rgb(175,0,0)",
    //         "width": 3
    //     },
    //     "type": "scatter"
    // },
    // {
    //     "x": [
    //         "1979.0680713128038",
    //         "1979.9594813614262",
    //         "1980.9319286871962",
    //         "1983.0794165316045",
    //         "1984.9837925445704",
    //         "1986.0372771474879",
    //         "1987.0097244732576",
    //         "1988.0226904376013",
    //         "1988.995137763371",
    //         "1991.0210696920583",
    //         "1991.9529983792545",
    //         "1993.9789303079417",
    //         "1995.1134521880065",
    //         "1996.0453808752027",
    //         "1997.0583468395462",
    //         "1998.07131280389",
    //         "1999.0437601296596",
    //         "2000.0162074554296",
    //         "2001.029173419773",
    //         "2002.1636952998379",
    //         "2003.0551053484603",
    //         "2005.0405186385738",
    //         "2006.9854132901135"
    //     ],
    //     "y": [
    //         "0.3671658093157635",
    //         "0.3712709639912621",
    //         "0.3737483264040589",
    //         "0.38930748361637657",
    //         "0.3942608871820168",
    //         "0.41059958776689454",
    //         "0.4016639067014305",
    //         "0.4098491121133113",
    //         "0.404989518004369",
    //         "0.4009794940455219",
    //         "0.40834683954619133",
    //         "0.41085855471777893",
    //         "0.4117107673877811",
    //         "0.41907811288845054",
    //         "0.42400244873511383",
    //         "0.4232202628426469",
    //         "0.4273280600380524",
    //         "0.4379575963638927",
    //         "0.4249471496018604",
    //         "0.4249841448805582",
    //         "0.4299045169473611",
    //         "0.4519801282502996",
    //         "0.45204354872806723"
    //     ],
    //     "name": "Market income plus tranfers minus federal taxes (holding 1979 progressivity)",
    //     "line": {
    //         "color": "rgb(0,117,28)",
    //         "width": 3
    //     },
    //     "type": "scatter"
    // }
]


export const layout = {
    "title": {
        // "text": "<b>Income inequality,by type of income measured,1979-2007</b>",
        "font": {
            "color": "",
            "family": "Arial, sans-serif",
            "size": 14
        }
    },
    "font": {
        "family": "Arial, sans-serif",
        "size": 12,
        "color": "#000"
    },
    "showlegend": true,
    "autosize": true,
    "width": 750,
    "height": 500,
    "xaxis": {
        "title": {
            "text": "<b>Date and Time </b>",
            "font": {
                "color": "",
                "family": "Arial, sans-serif",
                "size": 14
            }
        },

    },
    "yaxis": {
        "title": {
            "text": "Gini Index"
        },
    },
    // "legend": {
    //     "x": -0.002406144799079538,
    //     "y": 1.071535433070866,
    //     "traceorder": "normal",
    //     "font": {
    //         "family": "",
    //         "size": 11,
    //         "color": ""
    //     },
    //     "bgcolor": "rgba(255, 255, 255, 0)",
    //     "bordercolor": "rgba(0, 0, 0, 0)",
    //     "borderwidth": 0.1,
    //     "xanchor": "auto",
    //     "yanchor": "auto"
    // },

    "margin": {
        "l": 70,
        "r": 40,
        "b": 60,
        "t": 90,
        "pad": 2,
        "autoexpand": true
    },
    // "paper_bgcolor": "rgb(245, 245, 247)",
    // "plot_bgcolor": "rgb(245, 245, 247)",
    // "hovermode": "x",
    // "dragmode": "zoom",
    // "barmode": "stack",
    // "bargap": 0.2,
    // "bargroupgap": 0,
    // "boxmode": "overlay",
    // "separators": ".",
    // "hidesources": false
}


