import {configureStore, getDefaultMiddleware} from "@reduxjs/toolkit";
import {reduxBatch} from "@manaflair/redux-batch";
import {persistStore} from "redux-persist";
import {rootReducer} from "./rootReducer";
import http from '../utils/http';
import { createLogger } from 'redux-logger';
// import refreshMiddleware from "../utils/refreshMiddleware";

const middleware = process.env.REACT_APP_APP_ENV!=='prod'? [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  http,
  createLogger() ] : [
  ...getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false,
    thunk: true
  }),
  http
];

const store = configureStore({
  reducer: rootReducer,
  middleware,
  devTools: process.env.NODE_ENV !== "production",
  enhancers: [reduxBatch]
});

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

export default store;
