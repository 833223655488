const slugurlPair = {
    driver_management : process.env.REACT_APP_BACKEND_URL_DRIVER_MANAGEMENT,
    device_management : process.env.REACT_APP_BACKEND_URL_DEVICE_MANAGEMENT,
    battery_management : process.env.REACT_APP_BACKEND_URL_BATTERY_MANAGEMENT,
    token_management : process.env.REACT_APP_BACKEND_URL_TOKEN_MANAGEMENT,
    transaction_management : process.env.REACT_APP_BACKEND_URL_TRANSACTION_MANAGEMENT,
    garage_management : process.env.REACT_APP_BACKEND_URL_GARAGE_MANAGEMENT,
    default_url : process.env.REACT_APP_BACKEND_BASE_URL
};

export const getBaseUrl = (slug) => {
    return slug ? slugurlPair[slug] : slugurlPair.default_url;
};


