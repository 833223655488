import React, {useEffect, useState} from 'react';
import { useLocation } from "react-router";
import PropTypes from 'prop-types';
import {useForm} from "react-hook-form";
import {Button, Form, InputGroup, OverlayTrigger, Spinner} from "react-bootstrap";
import Select from "react-select";
import {SolInput, SolTextArea} from "../../../../components/SolStyledComponents/components";
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import {SolTypography} from "../../../../components/utilityComponents/SOlTypography";
import {DatePicker} from '@material-ui/pickers/'
import {ThemeProvider} from "@material-ui/styles";
import {createMuiTheme, makeStyles} from "@material-ui/core/styles";
import {actions} from "../../actions";
import {connect} from "react-redux";
import {upazillas} from "../../../../utils/countryData/3dListBangladesh";
import {toast} from "react-toastify";
import moment from "moment";
import {requestCycle} from "../../utils";
import * as bdData from "../../../../utils/countryData/3dListBangladesh";
import {usersReducer} from "../../reducer";
import {Toolbar} from "../components";
import {showNotifications} from "../../../../utils/notification";
import {GenericModal} from "../../../../components/genericModal/genericModal";
import {isValidPhoneNumber} from "libphonenumber-js";

const useStylesForDatePicker = makeStyles({
    root: {
        marginTop: '0',
        marginBottom: '0',
        border: 'none',
        width: '100%',
        '& input': {
            color: 'black',
            padding: "0 10px 0 10px",
            height: "38px",
            outline: "none",
        },
        '& input:hover, input:focus, input:active': {
            border: "1px solid #F18D00",
            boxShadow: "0px 0px 0px 3.2px r gba(241, 141, 0, 0.1)",
            outline: "none",
        }
    },
});
const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#F18D00',
        },
        secondary: {
            main: '#ffffff',
        }
    },
});
const CreateUser = props => {
    const {register, handleSubmit, errors, setError, clearErrors,control} = useForm();
    const location = useLocation();
    const [language, setLanguage] = useState("EN");
    const [name, setName] = useState(sessionStorage.getItem('name') ? sessionStorage.getItem('name'):"");
    const [userName, setUserName] = useState(sessionStorage.getItem('username') ? sessionStorage.getItem('username'):"");

    const [organisations, setOrganisations] = useState([]);
    const [roles, setRoles] = useState([]);
    const [org, setOrg] = useState(null);
    const [selectedRole, setSelectedRole] = useState(null);
    const [selectedGarage, setSelectedGarage] = useState(null);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [garages, setGarages] = useState([]);
    const [assignGarage, setAssignGarage] = useState(false);
    const [registrationData, setRegistrationData] = useState(false);
    const [edit, setEdit] = useState(sessionStorage.getItem('edit') ? true:false);
    const [errorMessage, setErrorMessage] = useState(false);

    const userRegistrationProcess = (data) => {
        let phoneIsValidated = isValidPhoneNumber(data.username, 'BD')
        let regex = /[?=.*!@#$&*]/
        let password = data.password
        let passWordValidated = regex.test(password)
        if (data.username.length !== 11) {
            setError('username', {
                type: "manual",
                message: language === "EN"? "Username must be a 11 digit phone number":"আইডি অবশ্যই ১১ সংখ্যার ফোন নাম্বার হতে হবে!"
            });
        } else if (!phoneIsValidated) {
            setError('username', {
                type: "manual",
                message: language === "EN"? "Username must be a valid phone number":"আইডিকে অবশ্যই সঠিক ফোন নাম্বার হতে হবে!"
            });
        } else if (password.length < 4) {
            setError('password', {
                type: "manual",
                message: language === "EN"? "Password must be of at least 4 character":"পাসওয়ার্ড অবশ্যই অন্তত পাঁচ ক্যারেক্টারের হতে হবে!"
            });
        }else if (data.password !== data.password_confirmation) {
            setError('password_confirmation', {
                type: "manual",
                message: language === "EN"? "Password does not match":"পাসওয়ার্ড মিলেনি!"
            });
            setError('password', {
                type: "manual",
                message: language === "EN"? "Password does not match":"পাসওয়ার্ড মিলেনি!"
            });
        } else {
            if (validateForm()===true) {
                const payload = {
                    ...data,
                    organization_guid: org?.value,
                    role_guid: selectedRole?.value,
                    garage_guids: selectedGarage? [selectedGarage.value]:[]
                }
                delete payload.password_confirmation

                props.CREATE_USER(payload);
            }
        }
    }

    const onSubmit=(data)=>{
        if (edit) {
            if (validateForm()===true){
                const payload = {
                    organization_guid: org.value,
                    role_guid: selectedRole.value,
                    name: name,
                    username: userName,
                    garage_guids: selectedGarage? [selectedGarage.value]:[]
                }
                props.EDIT_USER(payload, userName);
            }
        } else {
            if (!assignGarage) {
                setRegistrationData(data)
                setShowConfirmationModal(true)
            } else {
                userRegistrationProcess(data)
            }
        }
    };

    const {
        GET_ROLES,
        GET_ORGANISATIONS,
        GET_USER_DETAILS,
        GET_GARAGES
    } = props;

    const callError=(fieldname, message)=>{
        setError(fieldname, {
            type: "manual",
            message: message
        });
    }

    const validateForm=()=>{
        if (!selectedRole || !org)
        {
            const temp = [
                {
                    fieldName: 'role',
                    slug: language === 'EN'? "Role":"ব্যবহারকারীর ধরন",
                    decider: !!selectedRole,
                },
                {
                    fieldName: 'organisation',
                    slug: language === 'EN'? "Organisation":"প্রতিষ্ঠান",
                    decider: !!org,
                },
            ];
            temp.forEach((item)=>{
                let err = ''
                if (language === 'EN') {
                    err = `${item.slug} is required`
                } else {
                    err = `${item.slug} আবশ্যক!`
                }
                if (!item.decider){
                    callError(item.fieldName, err);
                }
            })
            return false
        }else{
            return true
        }
    }

    const clearSessionStorages = () => {
        sessionStorage.removeItem('edit')
        sessionStorage.removeItem('name')
        sessionStorage.removeItem('roleGuid')
        sessionStorage.removeItem('org')
        sessionStorage.removeItem('username')
        sessionStorage.removeItem('garageGuids')
    }

    useEffect(() => {
        if (props.successMessage !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessage)
            } else {
                toast.success('সফলভাবে ব্যবহারকারী নিবন্ধন সম্পন্ন হয়েছে!')
            }
        } else if (props.errorMessage !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessage, true))
        }
    }, [props.successMessage, props.errorMessage]);

    useEffect(() => {
        if (props.successMessageEdit !== undefined) {
            if (language === 'EN') {
                showNotifications('success', props.successMessageEdit)
            } else {
                toast.success('সফলভাবে ব্যবহারকারীর তথ্য সম্পাদিত হয়েছে!')
            }
        } else if (props.errorMessageEdit !== undefined) {
            setErrorMessage(showNotifications('error', props.errorMessageEdit, true))
        }
    }, [props.successMessageEdit, props.errorMessageEdit]);

    useEffect(()=>{
        setLanguage(props.language)
        GET_ORGANISATIONS()
        GET_ROLES()
        if (location.purpose && location.purpose === 'create') {
            setName("")
            setEdit(false)
            clearSessionStorages()
        }
        if (location.userData)
        {
            sessionStorage.setItem('edit', true)
            sessionStorage.setItem('name', location.userData.name)
            sessionStorage.setItem('roleGuid', location.userData.role_guid)
            sessionStorage.setItem('org', location.userData.organization_guid)
            if (location.userData.garage_guids) {
                sessionStorage.setItem('garageGuids', location.userData.garage_guids[0])
            }
            sessionStorage.setItem('username', location.userData.username)
            setName(location.userData.name)
            setUserName(location.userData.username)
            setEdit(true);
            GET_GARAGES({'organization_guid': location.userData.organization_guid})
        }
        if (edit) {
            GET_GARAGES({'organization_guid': sessionStorage.getItem('org')})
        }
    },[])

    useEffect(()=>{
        if (props.userUpdated === requestCycle.success){
            clearSessionStorages()
            props.history.push('/users/list/');
        }
    },[props.userUpdated])

    useEffect(()=>{
        if (props.userCreated === requestCycle.success){
            props.history.push('/users/list/');
        }
    },[props.userCreated])

    useEffect(()=>{
        const organisations = props.organisations;

        if (organisations && organisations.length >= 0) {
            setOrganisations(organisations.map((organisation) => {
                if (edit && sessionStorage.getItem('org') && organisation.pk === sessionStorage.getItem('org'))
                {
                    setOrg({
                        label: organisation.name,
                        value: organisation.pk
                    })
                }
                return {
                    ...organisation,
                    label: organisation.name,
                    value: organisation.pk,
                }
            }));
        }
    },[props.organisations])

    useEffect(()=>{
        const roleList = props.roles;

        if (roleList && roleList.length >= 0) {
            setRoles(roleList.map((role) => {
                if (edit && sessionStorage.getItem('roleGuid') && role.pk === sessionStorage.getItem('roleGuid'))
                {
                    setSelectedRole({
                        label: role.name,
                        value: role.pk
                    })
                }
                return {
                    ...role,
                    label: role.name,
                    value: role.pk,
                }
            }));
        }
    },[props.roles])


    useEffect(()=>{
        const garageList = props.garages;

        if (garageList && garageList.length >= 0) {
            setGarages(garageList.map((garage) => {
                if (edit && sessionStorage.getItem('garageGuids') && garage.pk === sessionStorage.getItem('garageGuids'))
                {
                    setSelectedGarage({
                        label: garage.name,
                        value: garage.pk
                    })
                }
                // TODO: Show "bangla_name" of a garage when language is bengali?
                return {
                    ...garage,
                    label: garage.name,
                    value: garage.pk,
                }
            }));
        }
    },[props.garages])

    const cancelProcess = () => {
        clearSessionStorages()
        props.history.goBack()
    }

    const assignGarageWhileRegistering = () => {
        GET_GARAGES({'organization_guid': org.value})
        setAssignGarage(true)
        setShowConfirmationModal(false)
    }

    const registerUser = () => {
        setAssignGarage(false)
        setShowConfirmationModal(false)
        userRegistrationProcess(registrationData)
    }

    const renderGarageAssinmentConfirmationModal = () => {
        return <>
            <GenericModal
                showModalHeader={true}
                footer={true}
                footerButtonSize={'sm'}
                footerCloseButtonText={'No'}
                footerActionButtonText={'Yes'}
                hideModal={registerUser}
                takeAction={assignGarageWhileRegistering}
                modal={showConfirmationModal}
                disableButton={props.userInfoSubmitting}
                hideCrossButton={true}
                title={<>Registering user: <SolTypography.Text
                    primary>Name</SolTypography.Text></>}>
                <p className={'no-padding'}><strong>Assign a garage to the user?</strong></p>
            </GenericModal>
        </>
    }

    return (
       <ContentWrapper showCardHeader={false} pageTitle={'Register user'} showBackButton={false} isLoading={false}>
               <div className={"row mb-6"}>
                   <div className={"col-md-12"}>
                       <Toolbar>
                           <Toolbar.Title>
                               <h1><b>{edit? (language === "EN"? "Edit user":"ব্যবহারকারীর তথ্য সম্পাদনা করুন"):(language === "EN"? "Register user":"ব্যবহারকারী নিবন্ধন করুন")}</b></h1>
                           </Toolbar.Title>
                       </Toolbar>
                   </div>
               </div>
               <div className={"row"}>
                   <div className={"col-md-12"}>
                       <Form onSubmit={handleSubmit(onSubmit)}>
                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <Form.Label>{language === 'EN'? 'Name':'নাম'}<span className="required text-danger">*</span></Form.Label>
                                       <SolInput
                                           type="text"
                                           name={"name"}
                                           ref={register({
                                               required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                           })}
                                           value={name}
                                           placeholder={language === "EN"? "Name of the user":"ব্যবহারকারীর নাম"}
                                           autoComplete={"off"}
                                           onChange={(e) => {setName(e.target.value)}}
                                       />
                                       {errors.name && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.name.message}</div>}
                                       {errorMessage.name? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.name}</div>:null}
                                   </Form.Group>
                               </div>
                           </div>

                           {
                               !edit && <div className={'row g-3'}>
                                   <div className={"col-md-6"}>
                                       <Form.Group>
                                           <div>
                                               <Form.Label>{language === "EN"? "Username":"লগইন করতে ব্যবহৃত আইডি"}<span className="required text-danger">*</span></Form.Label>
                                           </div>
                                           <SolInput
                                               name={'username'}
                                               ref={register({
                                                   required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                               })}
                                               placeholder={language === 'EN'? 'Provide phone number as username. Ex: 01676366473':'আইডি হিসেবে ব্যবহারের জন্য ফোন নাম্বার লিখুন। যেমনঃ 01676366473'}
                                               autoComplete={"off"}
                                           />
                                           {errors.username && <div className="text-danger">
                                               <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.username.message}</div>}
                                           {errorMessage.username? <div className="text-danger">
                                               <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.username}</div>:null}
                                       </Form.Group>
                                   </div></div>
                           }

                           {!edit && <div className={'row g-3'}>

                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === 'EN'? 'Password':'পাসওয়ার্ড'}<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           name={'password'}
                                           type={"password"}
                                           ref={register({
                                               required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                           })}
                                           placeholder={language === 'EN'? 'Password':'পাসওয়ার্ড'}
                                           autoComplete={"off"}
                                       />
                                       {errors.password && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.password.message}</div>}
                                       {errorMessage.password? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.password}</div>:null}
                                   </Form.Group>
                               </div>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === 'EN'? 'Password confirmation':'পাসওয়ার্ড নিশ্চিত করুন'}<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <SolInput
                                           name={'password_confirmation'}
                                           type={"password"}
                                           ref={register({
                                               required: language === 'EN'? 'Required':'উপরের তথ্যটি আবশ্যক!',
                                           })}
                                           placeholder={language === 'EN'? 'Password':'পাসওয়ার্ড'}
                                           autoComplete={"new-password"}
                                       />
                                       {errors.password_confirmation && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.password_confirmation.message}</div>}
                                   </Form.Group>
                               </div>
                           </div>}

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === 'EN'? 'Role':'ব্যবহারকারীর ধরন'}<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <Select
                                           name={`role`}
                                           placeholder={language === 'EN'? 'Select a Role':'ব্যবহারকারীর ধরন বাছাই করুন'}
                                           classNamePrefix="react-select-sol-style"
                                           isDisabled={props.filterDropdownLoading}
                                           isLoading={props.filterDropdownLoading}
                                           maxMenuHeight={200}
                                           value={selectedRole}
                                           isClearable={true}
                                           control={control}
                                           options={roles}
                                           isSearchable={true}
                                           onChange={(selected, {action}) => {
                                               if (action === "clear") {
                                                   setSelectedRole("")
                                               }
                                               setSelectedRole(selected)
                                               clearErrors();
                                           }}
                                       />
                                       {errors.role && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.role.message}</div>}
                                       {errorMessage.role? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.role}</div>:null}
                                   </Form.Group>
                               </div>
                           </div>

                           <div className={'row g-3'}>
                               <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label>{language === 'EN'? 'Organisation':'প্রতিষ্ঠান'}<span className="required text-danger">*</span></Form.Label>
                                       </div>
                                       <Select
                                           name={`organisation`}
                                           placeholder={language === 'EN'? 'Select an Organisation':'প্রতিষ্ঠান বাছাই করুন'}
                                           classNamePrefix="react-select-sol-style"
                                           isDisabled={props.filterDropdownLoading}
                                           isLoading={props.filterDropdownLoading}
                                           maxMenuHeight={200}
                                           value={org}
                                           isClearable={true}
                                           control={control}
                                           options={organisations}
                                           isSearchable={true}
                                           onChange={(selected, {action}) => {
                                               if (action === "clear") {
                                                   setOrg("")
                                                   setSelectedGarage("")
                                                   setGarages([])
                                               }
                                               if (selected) {
                                                   setOrg(selected)
                                                   setGarages([])
                                                   setSelectedGarage("")
                                                   GET_GARAGES({'organization_guid': selected.value})
                                               }
                                               clearErrors();
                                           }}
                                       />
                                       {errors.organisation && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.organisation.message}</div>}
                                       {errorMessage.organisation? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.organisation}</div>:null}
                                   </Form.Group>
                               </div>

                               {assignGarage || edit? <div className={"col-md-6"}>
                                   <Form.Group>
                                       <div>
                                           <Form.Label><b>{language === 'EN'? 'Garage':'গ্যারেজ'}</b></Form.Label>
                                       </div>
                                       <Select
                                           name={`garage`}
                                           placeholder={language === 'EN'? 'Select a garage':'গ্যারেজ বাছাই করুন'}
                                           classNamePrefix="react-select-sol-style"
                                           isDisabled={props.garageListLoading}
                                           isLoading={props.garageListLoading}
                                           maxMenuHeight={200}
                                           value={selectedGarage}
                                           isClearable={true}
                                           control={control}
                                           options={garages}
                                           isSearchable={true}
                                           onChange={(selected, {action}) => {
                                               if (action === "clear") {
                                                   setSelectedGarage("")
                                               }
                                               setSelectedGarage(selected)
                                               clearErrors()
                                           }}
                                       />
                                       {errors.garage && <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errors.garage.message}</div>}
                                       {errorMessage.role? <div className="text-danger">
                                           <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{errorMessage.garage}</div>:null}
                                   </Form.Group>
                               </div>:null}
                           </div>

                           <div className={'row'}>
                               <div className={"col-md-12"}>
                                   <Button variant="warning" type="submit"
                                           disabled={props.userInfoSubmitting || props.orgListLoading ||
                                           props.roleListLoading || props.garageListLoading ||
                                           (edit && props.garageListLoading)}>
                                       {props.userInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null} {edit? <><i className='fa fa-check' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Save':'সংরক্ষণ করুন'}</>: <><i className='fa fa-registered' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Register':'নিবন্ধন করুন'}</>}
                                   </Button>
                                   <Button variant="dark" size={'md'} onClick={() => cancelProcess()} style={{
                                       backgroundColor: '#8C8C9B',
                                       outline: '#8C8C9B',
                                       border: '#8C8C9B',
                                   }} className={"ml-3"}>
                                       <i className='fa fa-times' aria-hidden='true'/>&nbsp;{language === 'EN'? 'Cancel':'বাতিল করুন'}
                                   </Button>
                               </div>
                           </div>
                       </Form>
                   </div>
               </div>
           {renderGarageAssinmentConfirmationModal()}
           <style jsx>{`
              .react-select-sol-style__control:hover, .react-select-sol-style__control:focus, .react-select-sol-style__control:active{
                    border: 1px solid #F18D00;
                    box-shadow: 0 0 0 3.2px rgba(241, 141, 0, 0.1);
                    outline: none;  
                    }
                    
                .custom-popover{
                  min-width: fit-content;
                  min-height: fit-content;
                }
            `}</style>
       </ContentWrapper>
    );
};

CreateUser.propTypes = {};


const mapStateToProps=(state)=>{

    return {
        language: state.auth.language,
        isLoading: state.usersReducer.isLoading,
        organisations: state.usersReducer.organisations,
        samList: state.usersReducer.samList,
        roles: state.usersReducer.roles,
        garages: state.usersReducer.garages,
        orgListLoading: state.usersReducer.orgListLoading,
        roleListLoading: state.usersReducer.roleListLoading,
        garageListLoading: state.usersReducer.garageListLoading,
        userCreated: state.usersReducer.userCreated,
        userUpdated: state.usersReducer.userUpdated,
        userDetails: state.usersReducer.userDetails,
        userInfoSubmitting: state.usersReducer.userInfoSubmitting,
        successMessage: state.usersReducer.successMessage,
        errorMessage: state.usersReducer.errorMessage,
        successMessageEdit: state.usersReducer.successMessageEdit,
        errorMessageEdit: state.usersReducer.errorMessageEdit
    }
}

export default connect(mapStateToProps, actions) (CreateUser);
