const featureNames = {
    tokenFeature : 'token',
    userFeature: 'user',
    roleFeature: 'role',
    driverFeature: 'driver',
    checkOutFeature: 'driver_checkout',
    checkInFeature: 'driver_checkin',
    deviceFeature: 'device',
    // TODO: Need to verify the following
    smartDongleFeature: 'smart_dongle',
    batteryFeature: 'battery',
    // TODO: Need to verify the above
    appEventFeature: 'app_event',
    otaUpdateFeature: 'ota_update',
    firmwareFeature: 'firmware',
    hardwareFeature: 'hardware',
    organizationFeature: 'organization'
};

export const getFeatureName = (feature) => {
    return featureNames.hasOwnProperty(feature)? featureNames[feature]: undefined;
};
