import React, {useMemo} from "react";
import {Link, useHistory} from "react-router-dom";
import objectPath from "object-path";
import SVG from "react-inlinesvg";
import {toAbsoluteUrl} from "../../../_helpers";
import {useHtmlClassService} from "../../_core/MetronicLayout";

export function HeaderMobile() {
  const uiService = useHtmlClassService();
  const history = useHistory();

  const layoutProps = useMemo(() => {
    return {
      headerLogo: uiService.getStickyLogo(),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      headerMenuSelfDisplay:
          objectPath.get(uiService.config, "header.menu.self.display") === true,
      headerMobileCssClasses: uiService.getClasses("header_mobile", true),
      headerMobileAttributes: uiService.getAttributes("header_mobile")
    };
  }, [uiService]);

  const logoutClick = () => {
    history.push("/logout");
  };

  return (
      <>
        {/*begin::Header Mobile*/}
        <div
            id="kt_header_mobile"
            className={`header-mobile align-items-center ${layoutProps.headerMobileCssClasses}`}
            {...layoutProps.headerMobileAttributes}
        >
          {/*begin::Logo*/}
          <Link to="/">
            <img alt="logo" src={layoutProps.headerLogo}/>
          </Link>
          {/*end::Logo*/}

          {/*begin::Toolbar*/}
          <div className="d-flex align-items-center">
            {layoutProps.asideDisplay && (
                <>
                  {/*begin::Aside Mobile Toggle*/}
                  <button className="btn p-0 burger-icon burger-icon-left" id="kt_aside_mobile_toggle">
                    <span/>
                  </button>
                  {/*end::Aside Mobile Toggle*/}
                </>
            )}

            {/* TODO: Following section is commented out as it is not being used. But it will be needed if there are any header menu */}

            {/*{layoutProps.headerMenuSelfDisplay && (*/}
            {/*    <>*/}
            {/*      /!*begin::Header Menu Mobile Toggle*!/*/}
            {/*      <button className="btn p-0 burger-icon ml-4" id="kt_header_mobile_toggle">*/}
            {/*        <span/>*/}
            {/*      </button>*/}
            {/*      /!*end::Header Menu Mobile Toggle*!/*/}
            {/*    </>*/}
            {/*)}*/}

            {/*begin::Topbar Mobile Toggle*/}
            {/*TODO: Following had a class 'kt_header_mobile_topbar_toggle', which is removed*/}
            <button
                className="btn btn-hover-text-primary p-0 ml-2"
                onClick={logoutClick}
            >
              <span className="svg-icon svg-icon-xl">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Sign-out.svg")} />
              </span>
            </button>
            {/*end::Topbar Mobile Toggle*/}
          </div>
          {/*end::Toolbar*/}
        </div>
        {/*end::Header Mobile*/}
      </>
  );
}
