export const actionTypes = {

    /*Setting the isLoading flag to True.
    * isLoading flag is responsible for
    * the toggling spinner.*/
    SET_IS_LOADING_TO_TRUE: 'SET_IS_LOADING_TO_TRUE',
    RETURN_TO_INITIAL_STATE: 'RETURN_TO_INITIAL_STATE',

    GET_BATTERY_LIST_REQUEST: 'GET_BATTERY_LIST_REQUEST',
    GET_BATTERY_LIST_SUCCESS: 'GET_BATTERY_LIST_SUCCESS',
    GET_BATTERY_LIST_FAILURE: 'GET_BATTERY_LIST_FAILURE',

    GET_BATTERY_MODEL_LIST_REQUEST: 'GET_BATTERY_MODEL_LIST_REQUEST',
    GET_BATTERY_MODEL_LIST_SUCCESS: 'GET_BATTERY_MODEL_LIST_SUCCESS',
    GET_BATTERY_MODEL_LIST_FAILURE: 'GET_BATTERY_MODEL_LIST_FAILURE',

    GET_BATTERY_VENDOR_LIST_REQUEST: 'GET_BATTERY_VENDOR_LIST_REQUEST',
    GET_BATTERY_VENDOR_LIST_SUCCESS: 'GET_BATTERY_VENDOR_LIST_SUCCESS',
    GET_BATTERY_VENDOR_LIST_FAILURE: 'GET_BATTERY_VENDOR_LIST_FAILURE',

    GET_BMS_VENDOR_LIST_REQUEST: 'GET_BMS_VENDOR_LIST_REQUEST',
    GET_BMS_VENDOR_LIST_SUCCESS: 'GET_BMS_VENDOR_LIST_SUCCESS',
    GET_BMS_VENDOR_LIST_FAILURE: 'GET_BMS_VENDOR_LIST_FAILURE',

    GET_BMS_MODEL_LIST_REQUEST: 'GET_BMS_MODEL_LIST_REQUEST',
    GET_BMS_MODEL_LIST_SUCCESS: 'GET_BMS_MODEL_LIST_SUCCESS',
    GET_BMS_MODEL_LIST_FAILURE: 'GET_BMS_MODEL_LIST_FAILURE',

    GET_BATTERY_SUPPLIER_LIST_REQUEST: 'GET_BATTERY_SUPPLIER_LIST_REQUEST',
    GET_BATTERY_SUPPLIER_LIST_SUCCESS: 'GET_BATTERY_SUPPLIER_LIST_SUCCESS',
    GET_BATTERY_SUPPLIER_LIST_FAILURE: 'GET_BATTERY_SUPPLIER_LIST_FAILURE',

    ADD_BATTERY_REQUEST: 'ADD_BATTERY_REQUEST',
    ADD_BATTERY_SUCCESS: 'ADD_BATTERY_SUCCESS',
    ADD_BATTERY_FAILURE: 'ADD_BATTERY_FAILURE',

    UPDATE_DEVICE_REQUEST: 'UPDATE_DEVICE_REQUEST',
    UPDATE_DEVICE_SUCCESS: 'UPDATE_DEVICE_SUCCESS',
    UPDATE_DEVICE_FAILURE: 'UPDATE_DEVICE_FAILURE',


    GET_BATTERY_LOGS_REQUEST: 'GET_BATTERY_LOGS_REQUEST',
    GET_BATTERY_LOGS_SUCCESS: 'GET_BATTERY_LOGS_SUCCESS',
    GET_BATTERY_LOGS_FAILURE: 'GET_BATTERY_LOGS_FAILURE',

    GET_BATTERY_PERFORMANCE_REQUEST: 'GET_BATTERY_PERFORMANCE_REQUEST',
    GET_BATTERY_PERFORMANCE_SUCCESS: 'GET_BATTERY_PERFORMANCE_SUCCESS',
    GET_BATTERY_PERFORMANCE_FAILURE: 'GET_BATTERY_PERFORMANCE_FAILURE',

    GET_LIFETIME_AH_OUT_REQUEST: 'GET_LIFETIME_AH_OUT_REQUEST',
    GET_LIFETIME_AH_OUT_SUCCESS: 'GET_LIFETIME_AH_OUT_SUCCESS',
    GET_LIFETIME_AH_OUT_FAILURE: 'GET_LIFETIME_AH_OUT_FAILURE',
};
