import {combineReducers} from "redux";
import { authReducer } from "../features/auth/reducer";
import { dashboard } from "../pages/DashboardPage";
import refreshReducer from '../utils/refreshToken/reducer';
import { usersReducer } from "../features/users/reducer";
import { batteryReducer } from "../features/battery/reducer";
import { batteryLogReducer } from "../features/batteryLog/reducer";
import {commonReducer} from "../features/commonReduxActions/commonReducer";

export const rootReducer = combineReducers({
  tokenRefresh: refreshReducer,
  auth: authReducer,
  dashboard: dashboard,
  usersReducer: usersReducer,
  batteryReducer: batteryReducer,
  batteryLogReducer: batteryLogReducer,
  commonReducer: commonReducer
});
