import {actionTypes} from "./actionTypes"
import {CALL_API} from "../../utils/actionTypes"
export const actions = {
    SET_CONFIRMATION: (confirmation) => ({ type: actionTypes.SET_CONFIRMATION, payload: { confirmation } }),
    REGISTER: authToken => ({ type: actionTypes.REGISTER, payload: { authToken } }),
    LOGOUT: () => ({
        [CALL_API]: {
            endpoint: '/users/logout',
            method: 'POST',
            types: [
                actionTypes.LOGOUT_REQUEST,
                actionTypes.LOGOUT_SUCCESS,
                actionTypes.LOGOUT_FAILURE
            ]
        }
    }),
    // TODO: Update the following function
    SET_TOKEN: (user, access_token, refresh_token) => ({ type: actionTypes.SET_TOKEN, payload: { user, access_token, refresh_token } }),
    VERIFY_AUTH: ({ type: actionTypes.VERIFY_AUTH }),
    /*Action to Refresh the auth token*/
    REFRESH_AUTH_TOKEN: ({ type: actionTypes.REFRESH_AUTH_TOKEN }),
    /*Set the is_error flag to true and sends a messahge*/
    AUTH_ERROR: (msg) => ({ type: actionTypes.AUTH_ERROR, payload: { msg } }),
    /*Action to call the api to refresh the current access token.*/
    REFRESH_TOKEN: () =>  ({
        [CALL_API]: {
            endpoint: '/users/token/refresh',
            method: 'POST',
            types: [
                actionTypes.TOKEN_REFRESH_REQUEST,
                actionTypes.TOKEN_REFRESH_SUCCESS,
                actionTypes.TOKEN_REFRESH_FAILURE
            ]
        }
    }),
    
    LOGIN: (payload) =>  ({
        [CALL_API]: {
            endpoint: '/users/login',
            method: 'POST',
            body: payload,
            types: [
                actionTypes.LOGIN_REQUEST,
                actionTypes.LOGIN_SUCCESS,
                actionTypes.LOGIN_FAILURE
            ]
        }
    }),
    /*Action to call api to log user out*/
    ATTEMPT_LOGOUT: () => ({
        [CALL_API]: {
            endpoint: '/logout',
            method: 'POST',
            types: []
        }
    }),
    SET_ROLE: (payload)=> ({
        type: "SET_ROLE",
        payload: payload,
    }),
    START_LOADING_STATE: ()=> ({type: actionTypes.START_LOADING_STATE}),
    START_EMAIL_LOADING_STATE: ()=> ({type: actionTypes.START_EMAIL_LOADING_STATE}),
    DISABLE_LOADING: ()=> ({type: actionTypes.DISABLE_LOADING}),
    DISABLE_LOADING_IN_OTP: ()=> ({type: actionTypes.DISABLE_LOADING_IN_OTP}),
    CLEAR_CONFIRMATION_OBJECT: ()=> ({type: actionTypes.CLEAR_CONFIRMATION_OBJECT}),
    SET_JUST_REFRESHED_TOKEN: (token, data) => ({ type: actionTypes.SET_JUST_REFRESHED_TOKEN, payload: { token, data } }),
    SET_LANGUAGE: (language) => ({ type: actionTypes.SET_LANGUAGE, language: language}),

};
