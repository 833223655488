import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_BATTERY_LOG: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/historical_data/battery_logs`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_BATTERY_LOG_REQUEST,
                actionTypes.GET_BATTERY_LOG_SUCCESS,
                actionTypes.GET_BATTERY_LOG_FAILURE
            ]
        }
    }),

    RETURN_TO_INITIAL_STATE: ()=>({type: actionTypes.RETURN_TO_INITIAL_STATE})
};
