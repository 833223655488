import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import * as routerHelpers from "../../../_metronic/_helpers/RouterHelpers";
import {actionTypes} from './actionTypes';
import {actions} from './actions';
import Api from "./Api";
import { parsePhoneNumberFromString as parseMobile } from 'libphonenumber-js/mobile';
import {toast} from "react-toastify";

import { SET_ACCESS_TOKEN } from "redux-refresh-token";

/*Initial State object*/
const initialAuthState = {
  user: undefined,
  authToken: undefined,
  confirmation: undefined,
  Token: undefined,
  isError: false,
  errorMsg: '',
  access_token: undefined,
  refresh_token: undefined,
  role: '',
  disabled: false,
  is_solshare: undefined,
  organization_guid: undefined,
  requesting_for_auth: false,
  not_authenticated: true,
  authentication_request_response: null,
  logoutInProgress: false
};

/*Reducer for login page*/
export const authReducer = persistReducer(
    { storage, key: "solshare-auth", whitelist: ["user", "authorizations", "language", "authToken", "Token", "access_token",
        "refresh_token", "role", "user_guid", 'is_solshare', 'organization_guid' ] },
    (state = initialAuthState, action) => {
      switch (action.type) {
        case actionTypes.LOGIN_REQUEST:
          return {
            ...state,
            requesting_for_auth: true,
          };

        case actionTypes.LOGIN_SUCCESS:
          return {
            ...state,
            requesting_for_auth: false,
            not_authenticated: false,
            access_token: action.payload['access_token'],
            user: action.payload,
            role: action.payload['role'],
            authorizations: action.payload.authorizations? action.payload.authorizations:{},
            language: action.payload.language? action.payload.language: 'EN',
            authentication_request_response: action.payload
          };

        case actionTypes.LOGIN_FAILURE:
          return {
            ...state,
            requesting_for_auth: false,
            not_authenticated: true,
            authentication_request_response: action.payload
          };

        case actionTypes.LOGOUT_REQUEST: {
          return {
            ...state,
            logoutInProgress: true
          }
        }
        case actionTypes.LOGOUT_SUCCESS: {
          routerHelpers.forgotLastLocation();
          storage.removeItem('persist:solshare-auth')
          return initialAuthState;
        }
        case actionTypes.LOGOUT_FAILURE: {
          toast.error("Failed to logout!")
          return {
            ...state,
            logoutInProgress: false
          }
        }
        case actionTypes.VERIFY_AUTH: {
          return {...state};
        }
        case actionTypes.REFRESH_AUTH_TOKEN: {
          return { ...state };
        }
        case actionTypes.SET_TOKEN: {
          const { user, access_token, refresh_token } = action.payload;
          return { ...state, user: user, access_token: access_token, refresh_token: refresh_token, loading: false };
        }
        case "SET_ACCESS_TOKEN": {
          const {access_token} = action
          return { ...state , access_token: access_token};
        }
        case actionTypes.SET_LANGUAGE: {
          return { ...state , language: action.language? action.language: 'EN',};
        }

        default:
          return state;
      }
    }
);
