import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_DEVICE_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/devices`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_DEVICE_LIST_REQUEST,
                actionTypes.GET_DEVICE_LIST_SUCCESS,
                actionTypes.GET_DEVICE_LIST_FAILURE
            ]
        }
    }),

    GET_FIRMWARE_LIST: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler('/firmwares', payload),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_FIRMWARE_LIST_REQUEST,
                actionTypes.GET_FIRMWARE_LIST_SUCCESS,
                actionTypes.GET_FIRMWARE_LIST_FAILURE
            ]
        }
    }),
    GET_HARDWARE_LIST: (filter={}) => ({
        [CALL_API]: {
            endpoint: paramsHandler(`/hardwares`, filter),
            method: 'GET',
            backend_slug: 'device_management',
            types: [
                actionTypes.GET_HARDWARE_LIST_REQUEST,
                actionTypes.GET_HARDWARE_LIST_SUCCESS,
                actionTypes.GET_HARDWARE_LIST_FAILURE
            ]
        }
    }),

};
