import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import React, {Suspense, lazy, useEffect, useState} from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../../_metronic/layout";
import DashboardPage from "../pages/DashboardPage";
import { DemoRouter } from "../features/demoComponent/router";
import { UsersRouter } from "../features/users/router";
import { BatteriesRouter } from "../features/battery/router";
import { BatteryLogRouter } from "../features/batteryLog/router";
import { useSelector } from "react-redux";
import Home from "../features/home/Home";

export default function BasePage() {
    // const user = useSelector(state => state.auth)
    // const [role, setRole] = useState(null)
    // useEffect(() => {
    //     if (user) {
    //         setRole(user.user.role)
    //     }
    // }, [])

    return (
        <Suspense fallback={<LayoutSplashScreen/>}>
            <Switch>
                {/* Redirect from root URL to /dashboard. */}
                <Redirect exact from="/" to="/home"/>
                <ContentRoute path="/home" component={Home}/>
                <ContentRoute path="/users" component={UsersRouter}/>
                <ContentRoute path="/batteries" component={BatteriesRouter}/>
                <ContentRoute path="/battery-logs" component={BatteryLogRouter}/>
                <Redirect to="/error/error-v1"/>
            </Switch>
        </Suspense>
    );
}
