import {actionTypes} from "./actionTypes";
import {requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    successMessageForUpdate: undefined,
    errorMessageForUpdate: undefined,
    deviceCreated: undefined,
    deviceUpdated: undefined
};

/*Reducer for Device's redux store*/
export const batteryLogReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_BATTERY_LOG_REQUEST:
            return {
                ...state,
                listLoading: true,
                batteryLogs: undefined
            };
        case actionTypes.GET_BATTERY_LOG_SUCCESS:
            return {
                ...state,
                listLoading: false,
                batteryLogs: action.payload
            };
        case actionTypes.GET_BATTERY_LOG_FAILURE:
            toast.error("Can't load the battery log! Please contact with admin.");
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
