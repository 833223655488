/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {useLocation} from "react-router";
import {NavLink}  from "react-router-dom";
import DashboardOutlinedIcon from '@material-ui/icons/DashboardOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import GridOnRoundedIcon from '@material-ui/icons/GridOnRounded';
import SupervisorAccountOutlinedIcon from '@material-ui/icons/SupervisorAccountOutlined';
import AspectRatioOutlinedIcon from '@material-ui/icons/AspectRatioOutlined';
import SyncAltOutlinedIcon from '@material-ui/icons/SyncAltOutlined';
import {toAbsoluteUrl, checkIsActive} from "../../_metronic/_helpers";
import {useSelector} from "react-redux";
import {getServiceName} from "../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../utils/roleRelatedValues/featureNames";
import {getActionName} from "../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../features/auth/authorization";
import moment from "moment";

export function AsideMenuList({ layoutProps }) {
  const language = useSelector(state => state.auth.language? state.auth.language:'EN')
  const authorizations = useSelector(state => state.auth.authorizations? state.auth.authorizations:{})
  const role = useSelector(state => state.auth.role? state.auth.role:"")
  // Service names
  const driverService = getServiceName('driverService')
  const deviceService = getServiceName('deviceService')
  const tokenService = getServiceName('tokenService')
  const userService = getServiceName('userService')
  // Feature names
  const driverFeature = getFeatureName('driverFeature')
  const checkOutFeature = getFeatureName('checkOutFeature')
  const tokenFeature = getFeatureName('tokenFeature')
  const userFeature = getFeatureName('userFeature')
  const roleFeature = getFeatureName('roleFeature')
  const deviceFeature = getFeatureName('deviceFeature')
  const appEventFeature = getFeatureName('appEventFeature')
  const otaUpdateFeature = getFeatureName('otaUpdateFeature')
  const firmwareFeature = getFeatureName('firmwareFeature')
  const hardwareFeature = getFeatureName('hardwareFeature')
  // Action names
  const listAction = getActionName('listAction')

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url) ?
            `${!hasSubmenu && "menu-item-active"} menu-item-open`
            :
            "";
  };

  useEffect(() => {
    // For changing language at the calendars and dates
    if (language === 'BN') {
      moment.locale('bn');
    } else {
      moment.locale('en-gb')
    }
    // If no authorization JSON is available than dashboard will automatically log out
    if(!authorizations || !Object.keys(authorizations).length){
      document.location.href = '/logout';
    }
}, [authorizations]);


  return (
      <React.Fragment>
        {/* begin::Menu Nav */}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*----Driver Management----*/}
            {/*----Driver list, Checkout list----*/}
          {role !== 'Bkash Role'? <>
            {/*----Device Management----*/}
            {/*----Device list----*/}
            {checkAuthorization(authorizations, deviceService, deviceFeature, [listAction])? <>
              {/* begin: sub section */}
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/batteries/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                  <NavLink className="menu-link menu-toggle" to="/batteries/list">
                     <span className="svg-icon menu-icon">
                        <i className="fa fa-battery-full" aria-hidden="true"/>
                     </span>
                     <span className="menu-text sidebar-menu-item">
                       {language == 'EN'? 'Battery':'ব্যাটারী'}
                     </span>
                  </NavLink>
              </li>
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/batteries/battery_fleet", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/batteries/battery_fleet">
                     <span className="svg-icon menu-icon">
                        <i className="fa fa-chart-line" aria-hidden="true"/>
                     </span>
                  <span className="menu-text sidebar-menu-item">
                       {language === 'EN'? 'Battery Fleet Performance':'Chart Test'}
                     </span>
                </NavLink>
              </li>
              <li className={`menu-item menu-item-submenu ${getMenuItemActive("/battery-logs/list", false)}`}
                  aria-haspopup="true" data-menu-toggle="hover">
                <NavLink className="menu-link menu-toggle" to="/battery-logs/list">
                     <span className="svg-icon menu-icon">
                        <i className="fa fa-history" aria-hidden="true"/>
                     </span>
                  <span className="menu-text sidebar-menu-item">
                       {language == 'EN'? 'Data log':'তথ্য লগ'}
                     </span>
                </NavLink>
              </li>
            {/* end:: sub section */}
            </>:null}
            {/*----End of Device Management----*/}
          </>:null}
            {/*----End of Driver Management----*/}
        </ul>
        {/* end::Menu Nav */}
      </React.Fragment>
  );
}
