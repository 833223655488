import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import { Toolbar } from "./components";
import Plot from "react-plotly.js";
import { batteryLogsColumns, data, DataTableContainer, layout } from "../utils";
import { makeStyles } from "@material-ui/styles";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {DayPickerRangeController} from "react-dates";
import {GenericModal} from "../../../components/genericModal/genericModal";
import {SolTypography} from "../../../components/utilityComponents/SOlTypography";
import moment from "moment";
import Table from 'react-bootstrap/Table';

import {Badge, Breadcrumb, Button, Form, Spinner, OverlayTrigger, Popover} from "react-bootstrap";
import Select from "react-select";
import { SolInput } from "../../../components/SolStyledComponents/components";
import DataTable from "../../../components/dataTable/DataTable";
import { LoadingSpinner } from "../../../components/LoadingSpinnerForDataTable";
import batteryLogs from "../../batteryLog/views/batteryLogs/batteryLogs";
import { connect } from "react-redux";
import { actions } from "../actions";
import { withRoles } from "../../../router/SecuredRoute";
import { toast } from "react-toastify";
import { useLocation } from "react-router";
const useStylesIconButton = makeStyles({
  root: {
    marginTop: '0',
    paddingTop: '4px',
  },
});
const BatteryGraph = props => {

  const classes = useStylesIconButton();
  const [showFilter, setShowFilter] = useState(false);
  const [dateRange, setDateRange] = useState('');
  const [start, setStartDate] = useState(null);
  const [end, setEndDate] = useState(null);
  const [language, setLanguage] = useState("EN");
  const [authorization, setAuthorization] = useState(null);
  const [voltageData, setVoltageData] = useState(null);
  const [currentData, setCurrentData] = useState(null);
  const [lifetimeDischargeData, setLifetimeDischargeData] = useState(null);

  const [showBatteryDetailsModal, setShowBatteryDetailsModal] = useState(false);
  const [dongleSerial, setDongleSerial] = useState('');
  const [nameplateCapacity, setNameplateCapacity] = useState('');
  const [batteryNominalVoltage, setBatteryNominalVoltage] = useState('');
  const [referenceCapacity, setReferenceCapacity] = useState('');
  const [dischargeDelivery, setDischargeDelivery] = useState('');
  const [capacityLevel, setCapacityLevel] = useState('');
  const [batterySupplier, setBatterySupplier] = useState('');
  const [bmsVendor, setBmsVendor] = useState('');
  const [bmsModel, setBmsModel] = useState('');
  const [warrantyStartDate, setWarrantyStartDate] = useState('');
  const [warrantyEndDate, setWarrantyEndDate] = useState('');

  const location = useLocation();
  const {
    GET_BATTERY_LOGS,
    GET_BATTERY_LIST,
    RETURN_TO_INITIAL_STATE
  } = props

  const codeMap = {
    voltage: {
      code: "0002",
      formatting_data: {
        "name": "Battery Voltage (V)",
        "line": {
          "color": "rgb(35,1,244)",
          "width": 3
        },
        "type": "scatter"
      }
    },
    current:  {
      code: "0003",
      formatting_data: {
        "name": "Battery Current (A)",
        "line": {
          "color": "rgb(0,159,16)",
          "width": 3
        },
        "type": "scatter"
      }
    },
    lifetime_discharge:  {
      code: "0052",
      formatting_data: {
        "name": "Total discharge over lifetime (Ah_out)",
        "line": {
          "color": "rgb(244,1,1)",
          "width": 3
        },
        "type": "scatter"
      }
    },
  }
  const [batteryLogs, setBatteryLogs] = useState([]);

  const [focusedInput, setFocusedInput] = useState('startDate');

  const filterToggle = () => {
    setShowFilter(!showFilter)
  }

  const processGraphData =(data, code_key)=>{
    if (Array.isArray(data) && data.length>0)
    {
      return data.reduce((previousValue, currentValue, currentIndex, array) => {
        previousValue["x"].push(currentValue["generated_at"])
        previousValue["y"].push(currentValue[codeMap[code_key]["code"]])
        previousValue= {
          ...previousValue,
          ...codeMap[code_key]["formatting_data"]
        }
        return previousValue
      }, {x: [], y: []})
    }{
      return null
    }

  }


  useEffect(()=>{
    if (Array.isArray(props.batteryLogs) && props.batteryLogs.length>=0){
      setBatteryLogs(props.batteryLogs)
      setVoltageData(processGraphData(props.batteryLogs, "voltage"))
      setCurrentData(processGraphData(props.batteryLogs, "current"))
      setLifetimeDischargeData(processGraphData(props.batteryLogs, "lifetime_discharge"))
    }

  },[props.batteryLogs])


  const batteryLogsColumns = [
    {
      field: 'battery_serial_number',
      title: language === 'EN'? 'Battery Serial':'ব্যাটারী সিরিয়াল',
      emptyValue: ()=>{
        return "N/A"
      },
      cellStyle: {
        color: '#F18D00',
        paddingLeft: '0'
      },
      headerStyle: {
        paddingLeft: '0'
      },
      // disableClick: true,
    },
    {
      field: 'generated_at',
      title: language === 'EN'? 'Timestamp':'ডঙ্গল সিরিয়াল',
      emptyValue: ()=>{
        return "N/A"
      }
      // disableClick: true,
    },
    {
      field: '0002',
      title: language === 'EN'? 'Battery voltage(V)':'ব্যাটারী ক্যাপাসিটি (Ah)',
      emptyValue: ()=>{
        return "N/A"
      },
      // disableClick: true
    },
    {
      field: '0003',
      title: language === 'EN'? 'Battery current (A)':'ব্যাটারী ভোল্টেজ (v)',
      emptyValue: ()=>{
        return "N/A"
      },
      // disableClick: true
    },
    {
      field: '0052',
      title: language === 'EN'? 'Lifetime discharge (Ah_out)':'ব্যাটারী সরবরাহকারী',
      emptyValue: ()=>{
        return "N/A"
      },


      // disableClick: true
    },
    {
      field: '0052',
      title: language === 'EN'? 'Approx. km run':'ব্যাটারী সরবরাহকারী',
      emptyValue: ()=>{
        return "N/A"
      },
    }

  ]

  const disableFutureDt = (current) => {
    const today = moment().add('1', 'days')
    return current.isAfter(today)
  }

  const filterList=()=>{
    if (start && end){
      setVoltageData(null)
      setCurrentData(null)
      setLifetimeDischargeData(null)
      GET_BATTERY_LOGS({
        battery_serial_number: props.match.params.battery_serial_number,
        from_date: start.format('YYYY-MM-DD 00:00:00'),
        to_date: end.format('YYYY-MM-DD 23:59:59'),
      })
    }else {
      toast.error("Please select date range first ")
    }
  }

  const onDatesChange = ({startDate, endDate}) => {
    // setRequiredErrorMessage(false)
    setStartDate(startDate)
    setEndDate(endDate)
  }

  const resetFilter=()=>{
    RETURN_TO_INITIAL_STATE()
    GET_BATTERY_LOGS({
      battery_serial_number: props.match.params.battery_serial_number
    })
    setStartDate(null)
    setEndDate(null)
    setDateRange('')
  }

  useEffect(()=>{
    setLanguage("EN")
    setAuthorization(props.authorization)
    GET_BATTERY_LOGS({
      battery_serial_number: props.match.params.battery_serial_number
    })

    GET_BATTERY_LIST({'page': 1, 'serial_number': props.match.params.battery_serial_number})

    return ()=>{
      RETURN_TO_INITIAL_STATE()
    }


  },[])

  useEffect(() => {
    setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
  },[start, end])

  const handleFocusChange = (input) => {
    if (!input) {
      setFocusedInput('startDate')
    } else {
      setFocusedInput(input)
    }
  }

  const popover = (
    <Popover id="popover-basic" bsPrefix={'custom-popover'}>
      <Popover.Content bsPrefix={'custom-popover'}>
        <DayPickerRangeController
          {...props}
          startDate={start}
          endDate={end}
          numberOfMonths={2}
          minimumNights={0}
          isOutsideRange={disableFutureDt}
          onDatesChange={onDatesChange}
          focusedInput={focusedInput}
          onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
          keepOpenOnDateSelect={true}
        />
      </Popover.Content>
    </Popover>
  )

  const viewBatteryDetails = () => {
    setShowBatteryDetailsModal(true)
  }

  useEffect(() => {
    if (props.batteryList) {
      const batteryData = props.batteryList.results
      setDongleSerial(batteryData[0].dongle_serial_number)
      setNameplateCapacity(batteryData[0].nameplate_capacity)
      setBatteryNominalVoltage(batteryData[0].nameplate_voltage)
      setReferenceCapacity(batteryData[0].reference_capacity)
      setDischargeDelivery(batteryData[0].discharge_delivery)
      setCapacityLevel(batteryData[0].claim_reference_capacity)
      setBatterySupplier(batteryData[0].battery_supplier)
      setBmsVendor(batteryData[0].bms_vendor_name)
      setBmsModel(batteryData[0].bms_model_name)
      setWarrantyStartDate(batteryData[0].warranty_start_date? moment(batteryData[0].warranty_start_date).format('Do MMMM, YYYY'): '')
      setWarrantyEndDate(batteryData[0].warranty_end_date? moment(batteryData[0].warranty_end_date).format('Do MMMM, YYYY'): '')
    }
  },[props.batteryList])

  const hideBatteryDetailsModal = () => {
    setShowBatteryDetailsModal(false)
  }


  const renderBatteryDetailsModal = () => {
    return <>
      <GenericModal
          size={'lg'}
          showModalHeader={true}
          footer={true}
          footerButtonSize={'md'}
          noSubmitButton={true}
          footerCloseButtonText={language === 'EN'? 'Close':'বন্ধ করুন'}
          hideModal={hideBatteryDetailsModal}
          centered={true}
          modal={showBatteryDetailsModal}
          title={<>{language === 'EN'? 'Details of the battery ':'ব্যাটারী '}<SolTypography.Text
              primary>{props.match.params.battery_serial_number}</SolTypography.Text>{language !== 'EN'? ' এর বিস্তারিত তথ্য':null}</>}>
        <Table hover={true} borderless={true}>
          <tbody>
          <tr>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Battery Serial Number</th>
                  <td className={'float-right'}>{props.match.params.battery_serial_number}</td>
                </tr>
                </tbody>
              </Table>
            </td>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Dongle Serial Number</th>
                  <td className={'float-right'}>{dongleSerial? dongleSerial:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Nameplate capacity (Ah)</th>
                  <td className={'float-right'}>{nameplateCapacity? nameplateCapacity:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Battery nominal voltage (V)</th>
                  <td className={'float-right'}>{batteryNominalVoltage? batteryNominalVoltage:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Reference capacity by supplier</th>
                  <td className={'float-right'}>{referenceCapacity? referenceCapacity:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Guaranteed discharge delivery</th>
                  <td className={'float-right'}>{dischargeDelivery? dischargeDelivery:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Claim event reference capacity level (%)</th>
                  <td className={'float-right'}>{capacityLevel? capacityLevel:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Battery supplier</th>
                  <td className={'float-right'}>{batterySupplier? batterySupplier:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>BMS vendor</th>
                  <td className={'float-right'}>{bmsVendor? bmsVendor:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>BMS model</th>
                  <td className={'float-right'}>{bmsModel? bmsModel:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          <tr>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Warranty start date</th>
                  <td className={'float-right'}>{warrantyStartDate? warrantyStartDate:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
            <td>
              <Table borderless={true}>
                <tbody>
                <tr>
                  <th>Warranty end date</th>
                  <td className={'float-right'}>{warrantyEndDate? warrantyEndDate:'N/A'}</td>
                </tr>
                </tbody>
              </Table>
            </td>
          </tr>
          </tbody>
        </Table>
      </GenericModal>
    </>
  }


  return (
    <div>
      <ContentWrapper isLoading={false} showBackButton={false} showCardHeader={false}>
        <h1><b>Battery  graph of - {props.match.params.battery_serial_number}</b></h1>
        <div className={'mt-5 row'}>
          <div className={'col-md-12'}>
            <span><img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/></span>
            <span className={'ml-1 mr-5'} style={{minWidth: "100px", display: "inline-block" , padding: "0 .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Battery Details:</b></span>
            <span className={'ml-5 mr-5'} style={{minWidth: "150px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Capacity: </b> {nameplateCapacity? nameplateCapacity:'N/A'}</span>
            <span className={'ml-5 mr-5'} style={{minWidth: "350px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Guaranteed discharge delivery:  </b>{dischargeDelivery? dischargeDelivery:'N/A'}</span>
            <span className={'ml-5 mr-5'} style={{minWidth: "250px", display: "inline-block", background: "#D9D9D9" , padding: ".1rem .5rem", borderRadius: '7px'}}> <b style={{fontSize: "1.23rem"}}>Warranty end date: {warrantyEndDate? warrantyEndDate:'N/A'}</b></span>
            <span className={'ml-5 mr-5'}><button className={'btn btn-sm btn-warning'} onClick={viewBatteryDetails} disabled={props.detailsInfoLoading}>{props.detailsInfoLoading? <><Spinner animation="border"  size={'sm'} variant="light"/>&nbsp;</>:null}View more</button></span>
          </div>


          {/*<div className={'col-md-4'}>*/}
          {/*  <img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/>*/}
          {/*</div>*/}
          {/*<div className={'col-md-4'}>*/}
          {/*  <img src={require('../../../utils/asset/battery.svg')} alt={'battery'}/>*/}
          {/*</div>*/}
        </div>
        <br/>
        <br/>
        <br/>

        <div className={'row'}>
          <div className={'col-md-12'}>
            <Toolbar>
              <Toolbar.Title>
                <h1><b>Filter By Date</b></h1>
              </Toolbar.Title>
              <Toolbar.ToolbarContainer>
                <Toolbar.ToolbarContainer.Items>
                  {/*<IconButton onClick={filterToggle} classes={classes}>*/}
                  {/*  <FilterListIcon color="disabled" fontSize="large"/>*/}
                  {/*</IconButton>*/}

                </Toolbar.ToolbarContainer.Items>
              </Toolbar.ToolbarContainer>
            </Toolbar>
          </div>
        </div>


        <>
          <div className={"row"}>
            <div className={"col-md-12"}>
              <Form>
                <div className={"row g-3"}>
                  <div className={'col-md-6'}>
                    <Form.Group>
                      <div>
                        <Form.Label>{language === 'EN'? 'Date range':'তারিখের সীমা'}<span className="required text-danger">*</span></Form.Label>
                      </div>
                      <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                      overlay={popover}>
                        <SolInput
                          name={'dateRangePickerInput'}
                          readOnly={true}
                          value={dateRange}
                          placeholder={language === 'EN'? 'Select a date range':'তারিখের সীমা বাছাই করুন'}
                        />
                      </OverlayTrigger>
                      {/*{requiredErrorMessage? <><div className="text-danger">*/}
                      {/*  <i className="flaticon-warning kt-font-brand"></i>&nbsp;&nbsp;{generalRequiredMessage}</div></>:null}*/}
                    </Form.Group>
                  </div>
                </div>
                <div className={"row"}>
                  <div className={"col-md-12"}>
                    <Button variant="outline-dark" size={"sm"} onClick={() => {
                      resetFilter();
                    }}>
                      {language === "EN" ? "Reset filter" : "প্রাথমিক অবস্থায় ফিরে যান"}
                    </Button>
                    <Button variant="warning" size={"sm"} type="button"
                      // disabled={serialNumberNotNumericMessage? true:false}
                      onClick={filterList}
                      disabled={false}
                      className={"ml-3"}
                    >
                      {props.buttonLoading === true ? <><Spinner animation={"border"} size={"md"}
                                                                        variant={"light"} /> </> : null}
                      {language === "EN" ? "Apply filter" : "তালিকায় খুঁজুন"}
                    </Button>
                  </div>
                </div>
                {/*{requiredErrorMessage ? <div className="text-danger">*/}
                {/*  <i className="flaticon-warning kt-font-brand"></i>*/}
                {/*  &nbsp;&nbsp;{generalRequiredMessage}</div> : null}*/}
              </Form>
            </div>
          </div>
          <hr />
        </>
        {voltageData? <><br/>
          <br/>
          <Plot
            data={[voltageData]}
            layout={ { ...layout, "yaxis": {
                "title": {
                  "text": "Battery Voltage [V]"
                },
              }, }}
          /></>:null}
        {currentData? <>
          <br/>
          <br/>
          <br/>
          <Plot
            data={[currentData]}
            layout={ { ...layout, "yaxis": {
                "title": {
                  "text": "Battery Current [A]"
                },
              }, }}
          />
        </>:null}

        {lifetimeDischargeData?<>
          <br/>
          <br/>
          <br/>
          <Plot
            data={[lifetimeDischargeData]}
            layout={{ ...layout, "yaxis": {
                "title": {
                  "text": "Total discharge over lifetime [Ah_out]"
                },
              }, }}
          />
        </>:null}

        <br/>
        <br/>
        <DataTableContainer>
          {props.listLoading !== true ? <DataTable
            language={language}
            noDataAvailableMessageInBangla={'কোন ব্যাটারী নেই'}
            columns={batteryLogsColumns}
            data={batteryLogs}
            showToolbar={false}
            asyncPagination={true}
            isLoading={props.tableLoading}
            count={props.batteryList?.count}
            // itemsPerPage={props.batteryList?.page_size}
            // onChangePage={onChangePage}
            // page={page}
            // actionColumnIndex={-1}
            // // actions={checkAuthorization(authorization, deviceServiceName, deviceFeatureName, [updateActionName])? [{}]:[]}
            // actions={[{}]}
            // actionButtonVariant={['warning']}
            // actionButtonSize={'sm'}
            // actionButtonClickEvent={[viewBatteryLog]}
            // actionButtonText={language === 'EN'? ['View log']:['লগ দেখুন']}
          /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating battery list ..': 'ব্যাটারীর তালিকা প্রস্তুত হচ্ছে'} language={language}/>}
        </DataTableContainer>

        {renderBatteryDetailsModal()}
      </ContentWrapper>

    </div>
  );
};

BatteryGraph.propTypes = {};

const mapStateToProps = (state) => {
  return {
    language: state.auth.language,
    authorization: state.auth.authorizations,
    isLoading: state.batteryReducer.isLoading,
    tableLoading: state.batteryReducer.tableLoading,
    listLoading: state.batteryReducer.listLoading,
    iconLoading: state.batteryReducer.iconLoading,
    batteryLogs: state.batteryReducer.batteryLogs,
    batteryList: state.batteryReducer.batteryList,
    detailsInfoLoading: state.batteryReducer.detailsInfoLoading
  }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryGraph));
