import React, {Fragment, useEffect, useState} from 'react';
// import PropTypes from 'prop-types';
import ContentWrapper from "../../../../components/contentWrapper/contentWrapper";
import DataTable from "../../../../components/dataTable/DataTable";
import styled from "styled-components";
import {Filter, Toolbar} from "../components";
import FormContainer from "../components";
import {connect} from "react-redux";
import {actions} from "../../actions";
import {Badge, Breadcrumb, Button, Form, OverlayTrigger, Spinner, Popover} from "react-bootstrap";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import {CloudDownloadOutlined} from "@material-ui/icons";
import {SolInput} from "../../../../components/SolStyledComponents/components";
import {makeStyles} from "@material-ui/styles";
import {DataTableContainer, getLoadingSpinner} from '../../utils'
import {LoadingSpinner} from '../../../../components/LoadingSpinnerForDataTable'
import {Link} from "react-router-dom";
import {useSubheader} from "../../../../../_metronic/layout";
import {isNumber, JsonToUriSerialize, parseParams} from "../../../../utils/utilityFunctions";
import {withRoles} from "../../../../router/SecuredRoute";
import Select from "react-select";
import {getServiceName} from "../../../../utils/roleRelatedValues/serviceNames";
import {getFeatureName} from "../../../../utils/roleRelatedValues/featureNames";
import {getActionName} from "../../../../utils/roleRelatedValues/actionNames";
import {checkAuthorization} from "../../../auth/authorization";
import {batteryLogReducer} from "../../reducer";
import {DayPickerRangeController} from "react-dates";
import moment from "moment";
import {convertToAnotherLang} from "../../../../utils/convertLanguageOfNumbersWithCharacter";


const useStylesIconButton = makeStyles({
    root: {
        marginTop: '0',
        paddingTop: '4px',
    },
});

const BatteryLogs = (props) => {
    const [showFilter, setShowFilter] = useState(true);
    const [filterCriteria, setFilterCriteria] = useState(false);
    const [showFilterCriteriaMandatoryMessage, setShowFilterCriteriaMandatoryMessage] = useState(false);
    const [searchText, setSearchText] = useState("");
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(false);
    const [invalidBatterySerial, setInvalidBatterySerial] = useState(false);
    const [invalidDongleSerial, setInvalidDongleSerial] = useState(false);

    const [start, setStartDate] = useState(null);
    const [end, setEndDate] = useState(null);
    const [dateRange, setDateRange] = useState('');
    const [focusedInput, setFocusedInput] = useState('startDate');

    const [batterySerial, setBatterySerial] = useState(null);
    const [dongleSerial, setDongleSerial] = useState(null);
    const [batterySuppliers, setBatterySuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [capacities, setCapacities] = useState([]);
    const [selectedCapacity, setSelectedCapacity] = useState(null);
    const [voltages, setVoltages] = useState([]);
    const [selectedVoltage, setSelectedVoltage] = useState(null);

    const [batteryLog, setBatteryLog] = useState([]);
    const [language, setLanguage] = useState('EN');
    const [authorization, setAuthorization] = useState(null);
    const [page, setPage] = useState(1);
    const [gridOwnersList, setGridOwnersList] = useState([]);
    const [filterParameters, setFilterParameters] = useState(null);
    const [filterApplied, setFilterApplied] = useState(false);
    const subHeader = useSubheader();
    const [projectedParams, setProjectedParams] = useState(null);
    const { b2bOrgGuid , isSolshare } = props;

    // TODO: Need to update the following
    const [deviceServiceName, setDeviceServiceName] = useState(getServiceName('deviceService'));
    const [deviceFeatureName, setDeviceFeatureName] = useState(getFeatureName('deviceFeature'));
    const [createActionName, setCreateActionName] = useState(getActionName('createAction'));
    const [updateActionName, setUpdateActionName] = useState(getActionName('updateAction'));
    // TODO: Need to update the above

    const serialNumberFieldLabel = language === 'EN'? 'Serial Number':'যন্ত্রের ক্রমিক নাম্বার'
    const serialNumberFieldPlaceHolder = language === 'EN'? 'Provide smart dongle serial number. Ex: 33001234':'স্মার্ট ডংগলের ক্রমিক নাম্বার লিখুন। উদাহরণঃ 33001234'
    const generalRequiredMessage = language === 'EN'? 'Please provide at least one of the above information!':'দয়া করে অন্তত একটি তথ্য দিন!'

    // TODO: Update the Column names
    const batteryLogColumns = [
        {
            field: 'batteryId',
            title: language === 'EN'? 'ID of the battery':'ব্যাটারী আইডি',
            emptyValue: ()=>{
                return "N/A"
            },
            cellStyle: {
                color: '#F18D00',
                paddingLeft: '0'
            },
            headerStyle: {
                paddingLeft: '0'
            },
            // disableClick: true,
        },
        {
            field: 'timeAndDate',
            title: language === 'EN'? 'Time and date stamp':'সময় এবং তারিখ',
            emptyValue: ()=>{
                return "N/A"
            }
            // disableClick: true,
        },
        {
            field: 'voltage',
            title: language === 'EN'? 'Battery voltage (v)':'ব্যাটারী ভোল্টেজ (v)',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'current',
            title: language === 'EN'? 'Battery current (A)':'ব্যাটারীর তড়িৎ (A)',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'discharge',
            title: language === 'EN'? 'Lifetime discharge (Ah_out)':'এখন পর্যন্ত হওয়া ডিসচার্জ (Ah_out)',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        },
        {
            field: 'apprKmRun',
            title: language === 'EN'? 'Approx. Km run':'এখন পর্যন্ত যাওয়া কি.মি.',
            emptyValue: ()=>{
                return "N/A"
            },
            // disableClick: true
        }
    ]

    const {
        GET_BATTERY_LOG
    } = props;
    const classes = useStylesIconButton();

    const clearAllErrorMessages = () => {
        setRequiredErrorMessage(false)
    }
    const resetFilter = () => {
        resetFilterValues()
        clearAllErrorMessages()
        if (filterApplied) {
            // Filter will always be applied
            // Resetting the battery log
            setPage(1)
            collectTodaysData()
            setFilterApplied(false)
        }
    }

    const resetFilterValues = () => {
        setBatterySerial('')
        setDongleSerial('')
        setSelectedCapacity('')
        setSelectedVoltage('')
    }

    const filterTheList = () => {
        if (!batterySerial && !dongleSerial && !(start && end) && !selectedCapacity && !selectedVoltage) {
            setRequiredErrorMessage(true)
        } else {
            setFilterApplied(true)
            let filter = {page: 1}
            if (start && end) {
                let from_date = language === 'EN'? moment(start).format("YYYY-MM-DD"):convertToAnotherLang(moment(start).format("YYYY-MM-DD"))
                let to_date = language === 'EN'? moment(end).format("YYYY-MM-DD"):convertToAnotherLang(moment(end).format("YYYY-MM-DD"))
                filter['from_date'] = from_date + ' 00:00:00'
                filter['to_date'] = to_date + ' 23:59:59'
            }
            if (selectedCapacity) {
                filter['battery_capacity'] = selectedCapacity.value
            }
            if (selectedVoltage) {
                filter['battery_voltage'] = selectedVoltage.value
            }
            if (batterySerial) {
                filter['battery_serial_number'] = batterySerial
            }
            if (dongleSerial) {
                filter['dongle_serial_number'] = dongleSerial
            }
            GET_BATTERY_LOG(filter)
        }
    }

    const filterToggle = () => {
        setShowFilter(!showFilter)
    }

    const collectTodaysData = () => {
        let todaysDate = moment().format("YYYY-MM-DD")
        setStartDate(moment(todaysDate))
        setEndDate(moment(todaysDate))
        let from_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        let to_date = props.language === 'EN'? todaysDate:convertToAnotherLang(todaysDate)
        GET_BATTERY_LOG({from_date: from_date + " 00:00:00", to_date: to_date + " 23:59:59", page: 1})
        setFilterApplied(true)
        // TODO: Need to show the following text
        // setFilterText(language === 'EN'? 'between dates ' + moment(todaysDate).format("DD MMM,YYYY") + ' and ' +
        //     moment(todaysDate).format("DD MMM,YYYY"):moment(todaysDate).format("DD MMM,YYYY") + ' এবং ' +
        //     moment(todaysDate).format("DD MMM,YYYY") + ' তারিখের মাঝে')
    }

    useEffect(() => {
        setLanguage(props.language)
        setAuthorization(props.authorization)
        collectTodaysData()

        setCapacities([{
            label: '80',
            value: 80
        }, {
            label: '100',
            value: 100
        }, {
            label: '120',
            value: 120
        }])

        setVoltages([{
            label: '48',
            value: 48
        }, {
            label: '60',
            value: 60
        }])

        return ()=>{
            props.RETURN_TO_INITIAL_STATE();
        }
    }, []);


    useEffect(() => {
        if (props.batteryLogs) {
            const batteryLogs = props.batteryLogs;
            if (batteryLogs && batteryLogs.length > -1) {
                setBatteryLog(batteryLogs.map((log, index) => {
                    return {
                        ...log,
                        batteryId: log.battery_serial_number,
                        timeAndDate: log.generated_at,
                        voltage: log['0002']? log['0002']:null,
                        current: log['0003']? log['0003']:null,
                        discharge: log['0052']? log['0052']:null,
                        apprKmRun: log['0052']? log['0052']:null
                    }
                }))
            }
        }
    }, [props.batteryLogs]);

    const onChangePage = (event, newPage) => {
        setPage(newPage)
        if (filterCriteria && searchText) {
            let filter = {page: newPage}
            filter[filterCriteria.value] = searchText
            GET_BATTERY_LOG(filter)
        } else {
            GET_BATTERY_LOG({page: newPage});
        }
    }

    const viewBatteryLog = (rowData) => {
        props.history.push({
            pathname: `/batteries/battery_graph/${rowData.batteryId}`,
            purpose: 'details',
            batteryData: rowData
        })
    }

    const disableFutureDt = (current) => {
        const today = moment().add('1', 'days')
        return current.isAfter(today)
    }

    const onDatesChange = ({startDate, endDate}) => {
        setRequiredErrorMessage(false)
        setStartDate(startDate)
        setEndDate(endDate)
    }

    const handleFocusChange = (input) => {
        if (!input) {
            setFocusedInput('startDate')
        } else {
            setFocusedInput(input)
        }
    }

    const popover = (
        <Popover id="popover-basic" bsPrefix={'custom-popover'}>
            <Popover.Content bsPrefix={'custom-popover'}>
                <DayPickerRangeController
                    {...props}
                    startDate={start}
                    endDate={end}
                    numberOfMonths={2}
                    minimumNights={0}
                    isOutsideRange={disableFutureDt}
                    onDatesChange={onDatesChange}
                    focusedInput={focusedInput}
                    onFocusChange={(focusedInput) => handleFocusChange(focusedInput)}
                    keepOpenOnDateSelect={true}
                />
            </Popover.Content>
        </Popover>
    )

    useEffect(() => {
        setDateRange(start && end ? `${moment(start).format("DD MMM, YYYY")} - ${moment(end).format("DD MMM, YYYY")}` : '');
    },[start, end])

    return (
        <>
            <ContentWrapper showCardHeader={false} showBackButton={false} isLoading={props.isLoading}
                            serverError={false} permissionDenied={false} pageTitle={"Smart Dongles"}
                            statusCode={props.statusCode}>
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <Toolbar>
                            <Toolbar.Title>
                                <h1><b>{language === 'EN'? 'Data log':'তথ্য লগ'}</b></h1>
                            </Toolbar.Title>
                            <Toolbar.ToolbarContainer>
                                <Toolbar.ToolbarContainer.Items>
                                    <IconButton onClick={filterToggle} classes={classes}>
                                        <FilterListIcon color="disabled" fontSize="large"/>
                                    </IconButton>
                                </Toolbar.ToolbarContainer.Items>
                            </Toolbar.ToolbarContainer>
                        </Toolbar>
                    </div>
                </div>
                <hr/>
                {showFilter?
                    <>
                        <div className={'row'}>
                            <div className={'col-md-12'}>
                                <Form>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Battery serial':'ব্যাটারী সিরিয়াল'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'batterySerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Battery serial. Ex: 44000001':'ব্যাটারীর ক্রমিক নাম্বার। উদাহরণঃ 44000001'}
                                                    value={batterySerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setBatterySerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidBatterySerial(true)
                                                            } else {
                                                                setInvalidBatterySerial(false)
                                                            }
                                                        } else {
                                                            setBatterySerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidBatterySerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid battery serial!':'ভুল ' +
                                                    'ব্যাটারী সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Dongle serial':'ডঙ্গল সিরিয়াল'}</Form.Label>
                                                </div>
                                                <SolInput
                                                    name={'dongleSerial'}
                                                    type="text"
                                                    placeholder={language === 'EN'? 'Dongle serial. Ex: 44000001':'ডঙ্গলের সিরিয়াল। উদাহরণঃ 44000001'}
                                                    value={dongleSerial}
                                                    autoComplete={"off"}
                                                    onChange = {(e) => {
                                                        clearAllErrorMessages()
                                                        if (e.target.value) {
                                                            let value = e.target.value;
                                                            setDongleSerial(value)
                                                            if (isNaN(value) || value.includes('.')) {
                                                                setInvalidDongleSerial(true)
                                                            } else {
                                                                setInvalidDongleSerial(false)
                                                            }
                                                        } else {
                                                            setDongleSerial("")
                                                        }
                                                    }}
                                                />
                                                {invalidDongleSerial? <div className="text-danger">
                                                    <i className="flaticon-warning kt-font-brand"></i>
                                                    &nbsp;&nbsp;{language === 'EN'? 'Invalid Dongle serial!':'ভুল ' +
                                                    'ডঙ্গল সিরিয়াল!'}</div>:null}
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Time':'সময়'}<span className="required text-danger">*</span></Form.Label>
                                                </div>
                                                <OverlayTrigger rootClose trigger="click" placement="bottom-start"
                                                                overlay={popover}>
                                                    <SolInput
                                                        name={'dateRangePickerInput'}
                                                        readOnly={true}
                                                        value={dateRange}
                                                        placeholder={language === 'EN'? 'Select a time':'একটি সময় বাছাই করুন'}
                                                    />
                                                </OverlayTrigger>
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row g-3'}>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Capacity':'সক্ষমতা'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`capacity`}
                                                    placeholder={language === 'EN'? 'Select capacity':'সক্ষমতা বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedCapacity}
                                                    isClearable={true}
                                                    options={capacities}
                                                    isSearchable={true}
                                                    onChange={(selected) => {
                                                        // clearAllErrorMessages()
                                                        // if (selected) {
                                                        //     setEventType(selected)
                                                        //     setFilterData(data => ({
                                                        //         ...data,
                                                        //         [eventTypeFilterKeyName]: selected.value
                                                        //     }))
                                                        // } else {
                                                        //     setEventType("")
                                                        //     if (filterData.hasOwnProperty(eventTypeFilterKeyName)) {
                                                        //         let data = filterData
                                                        //         delete data[eventTypeFilterKeyName]
                                                        //         setFilterData(data)
                                                        //     }
                                                        // }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                        <div className={'col-md-4'}>
                                            <Form.Group>
                                                <div>
                                                    <Form.Label>{language === 'EN'? 'Voltage':'ভোল্টেজ'}</Form.Label>
                                                </div>
                                                <Select
                                                    name={`voltage`}
                                                    placeholder={language === 'EN'? 'Select voltage':'ভোল্টেজ বাছাই করুন'}
                                                    classNamePrefix="react-select-sol-style"
                                                    maxMenuHeight={200}
                                                    value={selectedVoltage}
                                                    isClearable={true}
                                                    options={voltages}
                                                    isSearchable={true}
                                                    onChange={(selected) => {
                                                        // clearAllErrorMessages()
                                                        // if (selected) {
                                                        //     setEventType(selected)
                                                        //     setFilterData(data => ({
                                                        //         ...data,
                                                        //         [eventTypeFilterKeyName]: selected.value
                                                        //     }))
                                                        // } else {
                                                        //     setEventType("")
                                                        //     if (filterData.hasOwnProperty(eventTypeFilterKeyName)) {
                                                        //         let data = filterData
                                                        //         delete data[eventTypeFilterKeyName]
                                                        //         setFilterData(data)
                                                        //     }
                                                        // }
                                                    }}
                                                />
                                            </Form.Group>
                                        </div>
                                    </div>
                                    <div className={'row'}>
                                        <div className={"col-md-12"}>
                                            <Button variant="outline-dark" size={'sm'} onClick={()=> {resetFilter()}}>
                                                {language === 'EN'? 'Reset filter':'প্রাথমিক অবস্থায় ফিরে যান'}
                                            </Button>
                                            <Button variant="warning" size={'sm'} type="button"
                                                    // disabled={serialNumberNotNumericMessage? true:false}
                                                    disabled={false}
                                                    onClick={()=> {filterTheList()}} className={'ml-3'}>
                                                {props.deviceInfoSubmitting===true? <><Spinner animation={'border'} size={'md'} variant={'light'}/> </>:null}
                                                {language === 'EN'? 'Apply filter':'তালিকায় খুঁজুন'}
                                            </Button>
                                        </div>
                                        {requiredErrorMessage? <div className="text-danger">
                                            <i className="flaticon-warning kt-font-brand"></i>
                                            &nbsp;&nbsp;{generalRequiredMessage}</div>:null}
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <hr/></>:null}
                <div className={'row'}>
                    <div className={'col-md-12'}>
                        <DataTableContainer>
                            {props.listLoading !== true ? <DataTable
                                language={language}
                                noDataAvailableMessageInBangla={'কোন তথ্য নেই'}
                                columns={batteryLogColumns}
                                data={batteryLog}
                                showToolbar={false}
                                asyncPagination={true}
                                isLoading={props.tableLoading}
                                count={props.batteryLog?.count}
                                itemsPerPage={props.batteryLog?.page_size}
                                onChangePage={onChangePage}
                                page={page}
                                actionColumnIndex={-1}
                                // actions={checkAuthorization(authorization, deviceServiceName, deviceFeatureName, [updateActionName])? [{}]:[]}
                                actions={[{}]}
                                actionButtonVariant={['warning']}
                                actionButtonSize={'sm'}
                                actionButtonClickEvent={[viewBatteryLog]}
                                actionButtonText={language === 'EN'? ['View graph']:['গ্রাফ দেখুন']}
                                // TODO: Need to pass filter Text and filter
                                // filterApplied={filterApplied}
                                // filterText={filterText}
                            /> : <LoadingSpinner loadingSubText={language == 'EN'? 'Generating battery log ..': 'ব্যাটারী  প্রস্তুত হচ্ছে'} language={language}/>}
                        </DataTableContainer>
                    </div>
                </div>

            </ContentWrapper>

            <style jsx>{`
              .filter-animation {
                animation-name: breath-in;
                animation-duration: 40ms;
              }

              @keyframes breath-in {
                0% {
                  height: 0;
                  opacity: 0;
                }
                10% {
                  height: 10px;
                  opacity: 0.10;
                }
                20% {
                  height: 20px;
                  opacity: 0.20;
                }
                30% {
                  height: 30px;
                  opacity: 0.30;
                }
                40% {
                  height: 40px;
                  opacity: 0.40;
                }
                50% {
                  height: 50px;
                  opacity: 0.50;
                }
                60% {
                  height: 60px;
                  opacity: 0.60;
                }
                70% {
                  height: 70px;
                  opacity: 0.70;
                }
                80% {
                  height: 80px;
                  opacity: 0.80;
                }
                90% {
                  height: 90px;
                  opacity: 0.90;
                }
                100% {
                  height: auto;
                  opacity: 1.00;
                }
              }

              //@keyframes breath-out {
              //    0% { height: auto;  opacity: 1.00; }
              //    10% { height: 90px;  opacity: 0.90; }
              //    20% { height: 80px;  opacity: 0.80; }
              //    30% { height: 70px;  opacity: 0.70; }
              //    40% { height: 60px;  opacity: 0.60; }
              //    50% { height: 50px;  opacity: 0.50; }
              //    60% { height: 40px;  opacity: 0.40; }
              //    70% { height: 30px;  opacity: 0.30; }
              //    80% { height: 20px;  opacity: 0.20; }
              //    90% { height: 10px;  opacity: 0.10; }
              //    100% { height: 0; opacity: 0;  }  
              //}


            `}</style>
        </>
    );
};

BatteryLogs.propTypes = {};

const mapStateToProps = (state) => {
    return {
        language: state.auth.language,
        authorization: state.auth.authorizations,
        isLoading: state.batteryLogReducer.isLoading,
        tableLoading: state.batteryLogReducer.tableLoading,
        statusCode: state.batteryLogReducer.statusCode,
        batteryLogs: state.batteryLogReducer.batteryLogs,
        filterTriggeredLoading: state.batteryLogReducer.filterTriggeredLoading,
        listLoading: state.batteryLogReducer.listLoading,
        iconLoading: state.batteryLogReducer.iconLoading
    }
}

export default connect(mapStateToProps, actions)(withRoles(BatteryLogs));
