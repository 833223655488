import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    userInfoSubmitting: undefined
};

/*Reducer for User's redux store*/
export const usersReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_USER_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                statusCode: undefined,
                userList: undefined,
                userDetails: undefined,
                userCreated: undefined,
                userUpdated: undefined,
                passwordUpdated: undefined,
                successMessage: undefined,
                errorMessage: undefined,
                successMessageEdit: undefined,
                errorMessageEdit: undefined,
                successMessagePassword: undefined,
                errorMessagePassword: undefined
            };
        case actionTypes.GET_USER_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                userList: action.payload
            };
        case actionTypes.GET_USER_LIST_FAILURE:
            toast.error("Can't load the user list! Please contact admin.")
            return {
                ...state,
                listLoading: false,
                statusCode: undefined
            };

        case actionTypes.GET_ORGANISATIONS_REQUEST:
            return {
                ...state,
                orgListLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_ORGANISATIONS_SUCCESS:
            return {
                ...state,
                orgListLoading: false,
                organisations: action.payload,
            };
        case actionTypes.GET_ORGANISATIONS_FAILURE:
            toast.error("Can't load the organization list, because: " + action.payload.error.msg);
            return {
                ...state,
                orgListLoading: false,
                statusCode: action.payload.status,

            };

        case actionTypes.GET_ROLES_REQUEST:
            return {
                ...state,
                roleListLoading: true,
                statusCode: undefined,
            };
        case actionTypes.GET_ROLES_SUCCESS:
            return {
                ...state,
                roleListLoading: false,
                roles: action.payload
            };
        case actionTypes.GET_ROLES_FAILURE:
            toast.error("Can't load the role list, because: " + action.payload.error.msg);
            return {
                ...state,
                roleListLoading: false,
                statusCode: action.payload.status,
            };

        case actionTypes.GET_GARAGES_REQUEST:
            return {
                ...state,
                garageListLoading: true
            };
        case actionTypes.GET_GARAGES_SUCCESS:
            return {
                ...state,
                garageListLoading: false,
                garages: action.payload
            };
        case actionTypes.GET_GARAGES_FAILURE:
            toast.error("Can't load the garage list, because: " + action.payload.error.msg);
            return {
                ...state,
                garageListLoading: false
            };

            //post Data
        case actionTypes.CREATE_USER_REQUEST:
            return {
                ...state,
                userCreated: undefined,
                userInfoSubmitting: true
            };
        case actionTypes.CREATE_USER_SUCCESS:
            return {
                ...state,
                userCreated: requestCycle.success,
                successMessage: action.payload,
                userInfoSubmitting: false
            };
        case actionTypes.CREATE_USER_FAILURE:
            return {
                ...state,
                userCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                userInfoSubmitting: false
            };

        case actionTypes.EDIT_USER_REQUEST:
            return {
                ...state,
                userUpdated: undefined,
                userInfoSubmitting: true
            };
        case actionTypes.EDIT_USER_SUCCESS:
            return {
                ...state,
                userUpdated: requestCycle.success,
                successMessageEdit: action.payload,
                userInfoSubmitting: false
            };
        case actionTypes.EDIT_USER_FAILURE:
            return {
                ...state,
                userUpdated: requestCycle.failed,
                errorMessageEdit: action.payload.error,
                userInfoSubmitting: false
            };

        case actionTypes.UPDATE_PASSWORD_REQUEST:
            return {
                ...state,
                passwordUpdated: undefined,
                userInfoSubmitting: true
            };
        case actionTypes.UPDATE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordUpdated: requestCycle.success,
                successMessagePassword: action.payload,
                userInfoSubmitting: false
            };
        case actionTypes.UPDATE_PASSWORD_FAILURE:
            return {
                ...state,
                passwordUpdated: requestCycle.failed,
                errorMessagePassword: action.payload.error,
                userInfoSubmitting: false
            };

        default:
            return state;
    }
};
