import {CALL_API} from "../../utils/actionTypes";
import {actionTypes} from "./actionTypes";
import {paramsHandler} from "../../utils/filterParamsHandler";
export const actions = {
    /* N.B: Check the documentation for each action's purpose in the actionTypes file */
    GET_USER_LIST: (filter)=>({
        [CALL_API]: {
            endpoint: paramsHandler('/users', filter),
            method: 'GET',
            types: [
                actionTypes.GET_USER_LIST_REQUEST,
                actionTypes.GET_USER_LIST_SUCCESS,
                actionTypes.GET_USER_LIST_FAILURE
            ]
        }
    }),

    GET_ORGANISATIONS: ()=>({
        [CALL_API]: {
            endpoint: `/organizations`,
            method: 'GET',
            types: [
                actionTypes.GET_ORGANISATIONS_REQUEST,
                actionTypes.GET_ORGANISATIONS_SUCCESS,
                actionTypes.GET_ORGANISATIONS_FAILURE,
            ]
        }
    }),

    GET_ROLES: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/roles`, payload),
            method: 'GET',
            types: [
                actionTypes.GET_ROLES_REQUEST,
                actionTypes.GET_ROLES_SUCCESS,
                actionTypes.GET_ROLES_FAILURE,
            ]
        }
    }),

    GET_GARAGES: (payload={})=>({
        [CALL_API]: {
            endpoint: paramsHandler(`/garages`, payload),
            method: 'GET',
            backend_slug: 'garage_management',
            types: [
                actionTypes.GET_GARAGES_REQUEST,
                actionTypes.GET_GARAGES_SUCCESS,
                actionTypes.GET_GARAGES_FAILURE
            ]
        }
    }),

    CREATE_USER: (payload = {})=>({
        [CALL_API]: {
            endpoint: '/users',
            method: 'POST',
            body : payload,
            types: [
                actionTypes.CREATE_USER_REQUEST,
                actionTypes.CREATE_USER_SUCCESS,
                actionTypes.CREATE_USER_FAILURE,
            ]
        }
    }),

    EDIT_USER: (payload = {}, id)=>({
        [CALL_API]: {
            endpoint: `/users/${id}`,
            method: 'POST',
            body : payload,
            types: [
                actionTypes.EDIT_USER_REQUEST,
                actionTypes.EDIT_USER_SUCCESS,
                actionTypes.EDIT_USER_FAILURE,
            ]
        }
    }),

    UPDATE_PASSWORD: (payload = {})=>({
        [CALL_API]: {
            endpoint: `/users/password`,
            method: 'POST',
            body : payload,
            types: [
                actionTypes.UPDATE_PASSWORD_REQUEST,
                actionTypes.UPDATE_PASSWORD_SUCCESS,
                actionTypes.UPDATE_PASSWORD_FAILURE,
            ]
        }
    }),
};
