import {actionTypes} from "./actionTypes";
import {exportGridListToCsv, requestCycle} from "./utils";
import {toast} from "react-toastify";

/*Initial State for grid*/
const initialState= {
    isLoading: false,
    successMessage: undefined,
    errorMessage: undefined,
    successMessageForUpdate: undefined,
    errorMessageForUpdate: undefined,
    deviceCreated: undefined,
    deviceUpdated: undefined
};

/*Reducer for Device's redux store*/
export const batteryReducer = (state=initialState, action)=> {
    /*Check the documentation for each reducer's purpose in the actionTypes file*/
    switch (action.type) {
        case actionTypes.GET_BATTERY_LIST_REQUEST:
            return {
                ...state,
                listLoading: true,
                detailsInfoLoading: true, // Added for view details modal
                batteryList: undefined
            };
        case actionTypes.GET_BATTERY_LIST_SUCCESS:
            return {
                ...state,
                listLoading: false,
                detailsInfoLoading: false,
                batteryList: action.payload
            };
        case actionTypes.GET_BATTERY_LIST_FAILURE:
            toast.error("Can't collect some necessary info! Please contact with admin.");
            return {
                ...state,
                listLoading: false,
                detailsInfoLoading: false
            };

        case actionTypes.GET_BATTERY_VENDOR_LIST_REQUEST:
            return {
                ...state,
                batteryVendorListDropDownLoading: true
            };
        case actionTypes.GET_BATTERY_VENDOR_LIST_SUCCESS:
            return {
                ...state,
                batteryVendorListDropDownLoading: false,
                batteryVendors: action.payload
            };
        case actionTypes.GET_BATTERY_VENDOR_LIST_FAILURE:
            toast.error("Failed to collect the battery vendor list!");
            return {
                ...state,
                batteryVendorListDropDownLoading: false
            };

        case actionTypes.GET_BATTERY_MODEL_LIST_REQUEST:
            return {
                ...state,
                batteryModelListDropDownLoading: true
            };
        case actionTypes.GET_BATTERY_MODEL_LIST_SUCCESS:
            return {
                ...state,
                batteryModelListDropDownLoading: false,
                batteryModels: action.payload
            };
        case actionTypes.GET_BATTERY_MODEL_LIST_FAILURE:
            toast.error("Failed to collect the battery model list!");
            return {
                ...state,
                batteryModelListDropDownLoading: false
            };

        case actionTypes.GET_BMS_VENDOR_LIST_REQUEST:
            return {
                ...state,
                bmsVendorListDropDownLoading: true
            };
        case actionTypes.GET_BMS_VENDOR_LIST_SUCCESS:
            return {
                ...state,
                bmsVendorListDropDownLoading: false,
                bmsVendors: action.payload
            };
        case actionTypes.GET_BMS_VENDOR_LIST_FAILURE:
            toast.error("Failed to collect the bms vendor list!");
            return {
                ...state,
                bmsVendorListDropDownLoading: false
            };

        case actionTypes.GET_BMS_MODEL_LIST_REQUEST:
            return {
                ...state,
                bmsModelListDropDownLoading: true
            };
        case actionTypes.GET_BMS_MODEL_LIST_SUCCESS:
            return {
                ...state,
                bmsModelListDropDownLoading: false,
                bmsModels: action.payload
            };
        case actionTypes.GET_BMS_MODEL_LIST_FAILURE:
            toast.error("Failed to collect the bms model list!");
            return {
                ...state,
                bmsModelListDropDownLoading: false
            };

        case actionTypes.GET_BATTERY_SUPPLIER_LIST_REQUEST:
            return {
                ...state,
                batterySuppliers: undefined,
                batterySuppliersDropDownLoading: true
            };
        case actionTypes.GET_BATTERY_SUPPLIER_LIST_SUCCESS:
            return {
                ...state,
                batterySuppliersDropDownLoading: false,
                batterySuppliers: action.payload
            };
        case actionTypes.GET_BATTERY_SUPPLIER_LIST_FAILURE:
            toast.error("Failed to collect the battery supplier list!");
            return {
                ...state,
                batterySuppliersDropDownLoading: false
            };

            //post Data
        case actionTypes.ADD_BATTERY_REQUEST:
            return {
                ...state,
                batteryCreated: undefined,
                batteryInfoSubmitting: true
            };
        case actionTypes.ADD_BATTERY_SUCCESS:
            return {
                ...state,
                batteryCreated: requestCycle.success,
                successMessage: action.payload,
                batteryInfoSubmitting: false
            };
        case actionTypes.ADD_BATTERY_FAILURE:
            return {
                ...state,
                batteryCreated: requestCycle.failed,
                errorMessage: action.payload.error,
                batteryInfoSubmitting: false
            };

        case actionTypes.UPDATE_DEVICE_REQUEST:
            return {
                ...state,
                deviceUpdated: undefined,
                deviceInfoSubmitting: true
            };
        case actionTypes.UPDATE_DEVICE_SUCCESS:
            return {
                ...state,
                deviceUpdated: requestCycle.success,
                successMessageForUpdate: action.payload,
                deviceInfoSubmitting: false
            };
        case actionTypes.UPDATE_DEVICE_FAILURE:
            return {
                ...state,
                deviceUpdated: requestCycle.failed,
                errorMessageForUpdate: action.payload.error,
                deviceInfoSubmitting: false
            };

        case actionTypes.GET_BATTERY_LOGS_REQUEST:
            return {
                ...state,
                batterySuppliers: undefined,
                listLoading: true
            };
        case actionTypes.GET_BATTERY_LOGS_SUCCESS:
            return {
                ...state,
                listLoading: false,
                batteryLogs: action.payload
            };
        case actionTypes.GET_BATTERY_LOGS_FAILURE:
            toast.error("Failed to collect the battery log list!");
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_BATTERY_PERFORMANCE_REQUEST:
            return {
                ...state,
                performanceData: undefined,
                listLoading: true
            };
        case actionTypes.GET_BATTERY_PERFORMANCE_SUCCESS:
            return {
                ...state,
                listLoading: false,
                performanceData: action.payload,
            }
        case actionTypes.GET_BATTERY_PERFORMANCE_FAILURE:
            toast.error("Failed to collect the battery performance data!");
            return {
                ...state,
                listLoading: false
            };

        case actionTypes.GET_LIFETIME_AH_OUT_REQUEST:
            return {
                ...state,
                dischargeData: undefined,
                listLoading: true
            };
        case actionTypes.GET_LIFETIME_AH_OUT_SUCCESS:
            return {
                ...state,
                listLoading: false,
                dischargeData: action.payload
            };
        case actionTypes.GET_LIFETIME_AH_OUT_FAILURE:
            toast.error("Failed to collect the battery discharge data!");
            return {
                ...state,
                listLoading: false
            };
        case actionTypes.RETURN_TO_INITIAL_STATE:
            return initialState

        default:
            return state;
    }
};
