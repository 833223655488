import React, { useState } from "react";
import PropTypes from "prop-types";
import Plot from 'react-plotly.js';
import { data, layout } from "../utils";
import ContentWrapper from "../../../components/contentWrapper/contentWrapper";
import { Toolbar } from "./components";
import IconButton from "@material-ui/core/IconButton";
import FilterListIcon from "@material-ui/icons/FilterList";
import { checkAuthorization } from "../../auth/authorization";
import { Button, Form, Spinner } from "react-bootstrap";
import Select from "react-select";
import { SolInput } from "../../../components/SolStyledComponents/components";
const ChartTest = props => {

};

ChartTest.propTypes = {

};

export default ChartTest;
